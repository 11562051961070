/** @format */
import { Casino } from "pages/Casino/Casino";
import { ResetPassword } from "pages/ResetPassword";
import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthWrapper from "wrappers/AuthWrapper";
import IframeWrapper from "../../pages/Iframe";
import { useAuth } from "../../utils/hooks/useAuth";
import Loading from "components/Loading";

// const Login = lazy(() => import("pages/Login"));
// const SignUp = lazy(() => import("pages/SignUp"));
const Home = lazy(() => import("pages/Home"));
const Lode = lazy(() => import("pages/Lode"));
const Sport = lazy(() => import("pages/Sport"));
const User = lazy(() => import("pages/User"));
const Deposit = lazy(() => import("pages/User/Deposit"));
const PlaceABetHistory = lazy(() => import("pages/Sport/PlaceABetHistory"));
const Admin = lazy(() => import("pages/Admin"));
const AdminLogin = lazy(() => import("pages/Admin/pages/Login"));
const TheThao = lazy(() => import("pages/TheThao/TheThao"));

export default function AppWrapper() {
  const { isLoading } = useAuth();
  return (
    <>
      <div className="root-wrapper">
        <Routes>
          {/* <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/" element={<AuthWrapper />}>
          <Route path="/tasks" element={<Tasks />} />
        </Route> */}
          <Route path="/" element={<AuthWrapper />}>
            <Route path="/" element={<Home />} />
            <Route path="/thethao" element={<TheThao />} />
            <Route path="/game-launch" element={<IframeWrapper />} />
            <Route path="/sport" element={<Sport />} />
            <Route path="/list-game" element={<Casino />} />
            <Route path="/lode/*" element={<Lode />} />
            <Route path="/sport/place-history" element={<PlaceABetHistory />} />
            <Route path="/password/reset" element={<ResetPassword />} />
            <Route path="/user/*" element={<User />} />
          </Route>
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

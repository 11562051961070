/** @format */

import { Form, Input, Modal } from "antd";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Button from "components/Button";
import { values } from "lodash";
import { sendResetPassword } from "api/authentication";
import { useSearchParams } from "react-router-dom";
import { history } from "App";
import { useModal } from "utils/hooks/useModal";
import { ModalNotifications } from "./ModalNotifications";
import { useAuth } from "utils/hooks/useAuth";

type IFormResetPassword = {
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
};

export const ModalResetPassword = () => {
  const [form] = Form.useForm<IFormResetPassword>();
  const [searchParams] = useSearchParams();
  const { showModal, hideModal } = useModal();
  const { setIsLoading } = useAuth();
  // tslint:disable-next-line:no-shadowed-variable
  const onFinish = (values: IFormResetPassword) => {
    setIsLoading(true);
    return sendResetPassword({
      data: {
        email: values?.email,
        password: values?.password,
        confirmPassword: values?.confirmPassword,
        token: searchParams.get("token"),
      },
    })
      .then((res) => {
        showModal(ModalNotifications, {
          message: "Đặt mật khẩu mới thành công",
          title: "Reset Password",
          actionText: "Trang chủ",
          action: () => history.push("/"),
        });
      })
      .catch((err) => {
        showModal(ModalNotifications, {
          message: "Đặt mật khẩu mới thất bại, vui lòng thử lại!",
          title: "Failed",
          actionText: "Trang chủ",
          action: () => hideModal(),
        });
      })

      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Modal
      title="Đặt lại mật khẩu"
      visible
      onCancel={() => {
        //   hideModal();
      }}
      footer={false}
      wrapClassName={classNames(styles.wrapModal)}
      centered={true}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label="Email" name="email" className="mb-10">
          <Input placeholder="Chỉ được nhập chữ và số, tối thiểu 6 ký tự" />
        </Form.Item>

        <Form.Item label="Mật khẩu mới" name="password" className="mb-10">
          <Input
            placeholder="Mật khẩu 6 đến 32 ký tự"
            className={classNames(styles.inputBackGround)}
          />
        </Form.Item>
        <Form.Item
          label="Nhập lại mật khẩu"
          name="confirmPassword"
          className="mb-10"
        >
          <Input
            placeholder="Mật khẩu 6 đến 32 ký tự"
            className={classNames(styles.inputBackGround)}
          />
        </Form.Item>
        <Form.Item className="text-center">
          <Button type="submit" className={classNames(styles.btnRegister)}>
            Xác nhận
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

/** @format */

// /** @format */

// /* eslint-disable react/jsx-props-no-spreading */
// /* eslint-disable react/jsx-curly-newline */
// /* eslint-disable react/no-unused-prop-types */
// /* eslint-disable react/require-default-props */
// /* eslint-disable @typescript-eslint/no-use-before-define */

// import Cookies from "js-cookie";
import { getCoinAllGame, getCoinMainAccount } from "api/gameLaunch";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { PropsWithChildren } from "react";
import { getUserInfo } from "../../api/authentication";
import { get } from "lodash";

type AuthContextProps = {
  token: string;
  setAuth: (p: any) => void;
  coin: number;
  name: string;
  listImage: any[];
  isLoading: boolean;
  setIsLoading: (p: boolean) => void;
  roles: string[];
  showTabTransfer?: false;
};

export const AuthContext = React.createContext<AuthContextProps>({
  token: "string",
  // tslint:disable-next-line:no-empty
  setAuth: () => {},
  coin: 0,
  name: "",
  listImage: [],
  isLoading: false,
  setIsLoading: () => {},
  roles: [],
  showTabTransfer: false,
});

export const AuthProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [authContext, setAuthContext] = React.useReducer(
    (o: any, n: any) => ({ ...o, ...n }),
    {
      token: "",
      coin: 0,
      name: "",
      listImage: [],
      isLoading: false,
      roles: [],
      showTabTransfer: false,
    }
  );

  const fnCommon = {
    getAllCoinInAllGame: async () => {
      try {
        const result: any = await getCoinAllGame();
        if (result?.data?.length) {
          return result?.data;
        }
        return [];
      } catch (error) {
        return [];
      }
    },
    getUserInfo: async () => {
      try {
        const result: any = await getUserInfo();
        if (result?.data?.length) {
          return result?.data;
        }
        return [];
      } catch (error) {
        return [];
      }
    },
  };
  const setAuth = (params: any) => {
    setAuthContext({ ...params });
  };

  const setIsLoading = (loading: boolean) => {
    setAuthContext({ isLoading: loading });
  };

  useEffect(() => {
    const accessToken = Cookies.get("token");
    if (!authContext.name && accessToken) {
      getUserInfo({
        config: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      }).then((user) =>
        setAuthContext({
          roles: get(user, "roles"),
          name: get(user, "username"),
          showTabTransfer: get(user, "showTransferCoin"),
        })
      );
    }
    setAuthContext({ token: accessToken });
  }, []);

  useEffect(() => {
    if (authContext.token) {
      const updateUserPerSeconds = setInterval(() => {
        getCoinMainAccount()
          .then((res) => {
            setAuthContext({ coin: get(res, "coin") });
          })
          .catch(() => {
            setAuthContext({ coin: 0 });
          });
      }, 10000);
      return () => {
        clearInterval(updateUserPerSeconds);
      };
    }
  }, [authContext.token]);

  useEffect(() => {
    fnCommon.getAllCoinInAllGame().then((res) => {
      setAuthContext({ listImage: res });
    });
  }, []);

  const value = { ...authContext, setAuth, setIsLoading };

  return <AuthContext.Provider value={value}> {children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const {
    token,
    coin,
    setAuth,
    name,
    listImage,
    roles,
    setIsLoading,
    showTabTransfer,
    isLoading,
  } = React.useContext(AuthContext);
  return {
    token,
    coin,
    setAuth,
    name,
    listImage,
    roles,
    showTabTransfer,
    setIsLoading,
    isLoading,
  };
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */
.styles_wrapHeader__QLM5w {
  background: #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  width: 100% !important;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
}
.styles_wrapHeader__QLM5w {
  /*#DIV_1*/
}
.styles_wrapHeader__QLM5w #DIV_1 {
  block-size: 16px;
  box-sizing: border-box;
  display: flex;
  height: 16px;
  inline-size: 16px;
  letter-spacing: 0.75px;
  width: 16px;
  border: 0px none rgb(245, 250, 255);
  margin: 0px 8px 0px 0px;
  display: none;
}
.styles_wrapHeader__QLM5w #IMG_2 {
  display: block;
  height: 16px;
  vertical-align: middle;
  width: 16px;
}
@media only screen and (max-width: 767px) {
  .styles_wrapHeader__QLM5w {
    padding: 15px;
  }
  .styles_wrapHeader__QLM5w .wraplogo img {
    width: 78px !important;
    height: 36px;
  }
  .styles_wrapHeader__QLM5w #DIV_1 {
    display: flex;
  }
}

.styles_wraplogo__4JWuv img {
  width: 139px !important;
  height: 44px;
}
@media only screen and (max-width: 767px) {
  .styles_wraplogo__4JWuv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .styles_wraplogo__4JWuv img {
    width: 78px !important;
    height: 36px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/styles.module.scss"],"names":[],"mappings":"AAAA,aAAA;AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,sBAAA;EACA,gBAAA;EACA,wBAAA;EACA,MAAA;EACA,YAAA;AAAF;AACE;EAYI,SAAA;AAVN;AADI;EACE,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,WAAA;EACA,mCAAA;EACA,uBAAA;EACA,aAAA;AAGN;AAAI;EACE,cAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;AAEN;AACI;EArBF;IAsBI,aAAA;EAEJ;EAAM;IACE,sBAAA;IACA,YAAA;EAER;EACI;IACE,aAAA;EACN;AACF;;AAME;EACE,uBAAA;EACA,YAAA;AAHJ;AAMI;EADF;IAEI,aAAA;IACA,uBAAA;IACA,mBAAA;IACA,SAAA;EAHJ;EAII;IACE,sBAAA;IACA,YAAA;EAFN;AACF","sourcesContent":["/** @format */\n\n.wrapHeader {\n  background: #1d1d1d;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 30px;\n  width: 100% !important;\n  position: sticky;\n  position: -webkit-sticky;\n  top: 0;\n  z-index: 999;\n  :global {\n    #DIV_1 {\n      block-size: 16px;\n      box-sizing: border-box;\n      display: flex;\n      height: 16px;\n      inline-size: 16px;\n      letter-spacing: 0.75px;\n      width: 16px;\n      border: 0px none rgb(245, 250, 255);\n      margin: 0px 8px 0px 0px;\n      display: none;\n    } /*#DIV_1*/\n\n    #IMG_2 {\n      display: block;\n      height: 16px;\n      vertical-align: middle;\n      width: 16px;\n    }\n\n    @media only screen and (max-width: 767px) {\n      padding: 15px;\n      .wraplogo {\n        img {\n          width: 78px !important;\n          height: 36px;\n        }\n      }\n      #DIV_1 {\n        display: flex;\n      }\n    }\n  }\n}\n\n.wraplogo {\n\n  img {\n    width: 139px !important;\n    height: 44px;\n  }\n  :global {\n    @media only screen and (max-width: 767px) {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      gap: 20px;\n      img {\n        width: 78px !important;\n        height: 36px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapHeader": `styles_wrapHeader__QLM5w`,
	"wraplogo": `styles_wraplogo__4JWuv`
};
export default ___CSS_LOADER_EXPORT___;

/** @format */

import {
  CaretDownOutlined,
  CaretUpOutlined,
  Loading3QuartersOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, notification, Popover, Spin } from "antd";
import { getUserInfo } from "api/authentication";
import {
  getCoinAllGame,
  getCoinMainAccount,
  getCoinUser,
  refundCoinFromGame,
} from "api/gameLaunch";
import { AxiosError } from "axios";
import { ModalNotifications } from "components/Modal";
import { FC, useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "utils/hooks/useAuth";
import { useModal } from "utils/hooks/useModal";
import styles from "./styles.module.scss";
import { Typography } from "antd";
import { formatAmount } from "../../utils/helper";
const { Text } = Typography;

type IUserInfo = {
  username: string;
  phoneNumber: string;
  email: string;
  coin: number;
  isOpen: false;
};

type ICoinInfo = {
  productType?: number;
  gameCode?: string;
  title?: string;
  closePopover?: any;
  open?: boolean;
  coinFromGame?: number;
  maintain?: boolean;
};

const CoinInfo: FC<ICoinInfo> = ({
  productType,
  gameCode,
  title,
  closePopover,
  open,
  coinFromGame = 0,
  maintain,
}) => {
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [coin, setCoin] = useState<number>(0);
  const { showModal } = useModal();

  const fnCommon = {
    getCoinUserFromGame: async () => {
      try {
        const res: any = await getCoinUser({
          config: {
            params: {
              product_type: productType,
            },
          },
        });
        return res.balance;
      } catch (err) {
        return 0;
      }
    },
    refundOneGame: async () => {
      try {
        const result = await refundCoinFromGame({
          config: {
            params: { productType },
          },
        });
      } catch (err) {
        throw err;
      }
    },
  };

  const handler = {
    hanlderRefundOneGame: async () => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[2] = true;
        return newLoadings;
      });
      setTimeout(async () => {
        try {
          const result = await fnCommon.refundOneGame();
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[2] = false;
            return newLoadings;
          });
          closePopover(false);
          showModal(ModalNotifications, {
            message: `Rút tiền thành công. Bạn đã rút ${coin} D`,
          });
        } catch (error) {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[2] = false;
            return newLoadings;
          });
          closePopover(false);
          showModal(ModalNotifications, {
            message: `Rút tiền thất bại, vui lòng thử lại sau`,
          });
        }
      }, 2000);
    },
  };

  useEffect(() => {
    if (open) {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[0] = true;
        return newLoadings;
      });
      setCoin(coinFromGame);
      setTimeout(() => {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[0] = false;
          return newLoadings;
        });
      }, 2000);
    }
  }, [open, coinFromGame]);
  return (
    <>
      <li id="LI_3">
        <span id="SPAN_4">{title}</span>
        {!loadings[0] ? (
          <>
            <span id="SPAN_5">{formatAmount(coin)}</span>
            {coin ? (
              <Button
                type="primary"
                size="small"
                style={{ borderRadius: "5px", width: "100%" }}
                onClick={handler.hanlderRefundOneGame}
              >
                {loadings[2] ? <Loading3QuartersOutlined spin /> : "chuyển về"}
              </Button>
            ) : maintain ? (
              <Text type="danger">Đang bảo trì</Text>
            ) : null}
          </>
        ) : (
          <span id="SPAN_5">
            <Spin indicator={<Loading3QuartersOutlined spin />} />
          </span>
        )}
      </li>
    </>
  );
};

export const UserInfo = () => {
  const [userInfo, setUserInfo] = useReducer(
    (o: any, n: any) => ({ ...o, ...n }),
    {
      username: "",
      phoneNumber: "",
      email: "",
      coin: 0,
      allGame: [],
    }
  );

  const [open, setOpen] = useState(false);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const { showModal } = useModal();
  const { token, coin: coinContext } = useAuth();

  const fnCommon = {
    getUserInfo: async () => {
      return getUserInfo();
    },
    formatMoneyType: (money = 0) => {
      const format = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return format.format(money);
    },

    refundAllGame: async () => {
      try {
        const result = await refundCoinFromGame();
      } catch (error) {
        throw error;
      }
    },

    getAllCoinInAllGame: async () => {
      try {
        const result: any = await getCoinAllGame();
        if (result?.data?.length) {
          return result?.data;
        }
        return [];
      } catch (error) {
        return [];
      }
    },

    getCoinUserMain: async () => {
      try {
        const mainCoin = await getCoinMainAccount();
        return (mainCoin as any).coin;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          throw error;
        }
        return 0;
      }
    },
  };

  const handler = {
    handleOpenChange: (newOpen: boolean) => {
      setOpen(newOpen);
    },
    handlerRefundAllGame: async () => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[1] = true;
        return newLoadings;
      });
      setTimeout(async () => {
        try {
          const result = await fnCommon.refundAllGame();
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = false;
            return newLoadings;
          });
          setOpen(false);
          showModal(ModalNotifications, {
            message: `Rút tiền thành công. Bạn đã rút ${
              userInfo?.allGame?.length
                ? formatAmount(
                    userInfo?.allGame.reduce((a: any, b: any) => a + b.coin, 0)
                  )
                : 0
            } D`,
          });
        } catch (error) {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = false;
            return newLoadings;
          });
          setOpen(false);
          showModal(ModalNotifications, {
            message: `Rút tiền thất bại, vui lòng thử lại sau`,
          });
        }
      }, 2000);
    },
  };

  const requestUser = () => {
    fnCommon
      .getUserInfo()
      .then((res: any) =>
        setUserInfo({
          username: res.username,
          phoneNumber: res.phoneNumber,
          email: res.email,
          coin: res.coin,
        })
      )
      .catch((err: AxiosError) => {
        notification.error({ message: err?.message });
      });
  };

  useEffect(() => {
    requestUser();
  }, []);

  useEffect(() => {
    if (token) {
      // const updateUserPerSeconds = setInterval(() => {
      //   fnCommon.getCoinUserMain().then((res) => {
      setUserInfo({ coin: coinContext });
      //   });
      // }, 10000);
      // return () => {
      //   clearInterval(updateUserPerSeconds);
      // };
    }
  }, [token, coinContext]);

  useEffect(() => {
    fnCommon.getAllCoinInAllGame().then((res) => setUserInfo({ allGame: res }));
  }, [open]);
  const content = () => (
    <div className={styles.contentTransferMoney}>
      <ul id="UL_1">
        {userInfo?.allGame?.map((item: any, index: any) => (
          <CoinInfo
            key={index}
            gameCode={item.productCode}
            title={item.label}
            productType={item.productCode}
            closePopover={setOpen}
            coinFromGame={item.coin}
            maintain={item.maintain}
            open={open}
          />
        ))}
        <li
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              size="small"
              style={{ borderRadius: "5px" }}
              onClick={handler.handlerRefundAllGame}
            >
              {loadings[1] ? (
                <Loading3QuartersOutlined spin />
              ) : (
                "chuyển hết về tài khoản chính"
              )}
            </Button>
          </span>
          <span
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            id="SPAN_5"
          >
            {userInfo?.allGame?.length
              ? formatAmount(
                  userInfo?.allGame.reduce((a: any, b: any) => a + b.coin, 0)
                )
              : 0}
          </span>
        </li>
      </ul>
    </div>
  );
  return (
    <>
      <Link to={"/user"}>
        <Avatar
          style={{ backgroundColor: "#ec5528" }}
          size={44}
          icon={<UserOutlined />}
          className="avatar"
        />
      </Link>
      <div className={styles.wrapUserInfo}>
        <div className="username">{userInfo.username}</div>

        <Popover
          placement="bottomRight"
          content={content}
          onOpenChange={handler.handleOpenChange}
          trigger="click"
          overlayClassName={styles.popoverTransferMoney}
          open={open}
        >
          <span style={{ cursor: "pointer" }} className="coin">
            {fnCommon.formatMoneyType(userInfo.coin)}
          </span>
          {open ? (
            <CaretUpOutlined style={{ color: "white" }} />
          ) : (
            <CaretDownOutlined style={{ color: "white" }} />
          )}
        </Popover>
      </div>
    </>
  );
};

/** @format */

import { v4 as uuidv4 } from "uuid";

import Axios from "axios";
import configs from "constants/config";
import Cookies from "js-cookie";
import { logout } from "utils/helper/authentication";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { notification } from "antd";
import Cookie from "js-cookie";
import { AUTH_API } from "utils/definitions/api";

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    // eslint-disable-next-line no-param-reassign
    const token = Cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    const originalConfig = error.config;
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    const refreshToken = Cookies.get("refreshToken");
    if (!refreshToken) {
      logout();
      return Promise.reject(error);
    }
    return Axios.post(
      `${configs.API_DOMAIN}/v1/app/auth/request-access-token`,
      {
        refreshToken,
      }
    )
      .then((res: any) => {
        if (res.status === 200) {
          const data = res.data.data;
          Cookies.set("token", data.token);
          originalConfig.headers.Authorization = `Bearer ${data.token}`;
          return Axios(originalConfig);
        } else {
          logout();
          return Promise.reject(error);
        }
      })
      .catch(() => {
        logout();
        return Promise.reject(error);
      });
  }
);

export const sendGet = (url: string, params?: any) =>
  axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any) =>
  axiosInstance
    .post(url, params, { params: queryParams })
    .then((res) => res.data);
export const sendPut = (url: string, params?: any) =>
  axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url: string, params?: any) =>
  axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url: string, params?: any) =>
  axiosInstance.delete(url, { params }).then((res) => res.data);
class Http {
  private _http: AxiosInstance;

  /**
   *
   * @param options AxiosRequestConfig
   */
  public constructor(options: AxiosRequestConfig) {
    // eslint-disable-next-line no-underscore-dangle
    this._http = axios.create(options);
    this.interceptorsRequest();
    this.interceptorsResponse();
  }

  /**
   *
   * @param url string
   * @param config AxiosRequestConfig
   */
  public get(
    url: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    return this._http.get(url, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig | undefined
  ) {
    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem("deviceId", deviceId as string);
    }
    return this._http.post(
      url,
      {
        ...data,
        ...{
          deviceInfo: {
            deviceId,
            deviceType: "DEVICE_TYPE_ANDROID",
            notificationToken: "N1",
          },
        },
      },
      config
    );
  }
  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public sendPost(
    url: string,
    data?: any,
    config?: AxiosRequestConfig | undefined
  ) {
    return this._http.post(url, data, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public put(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return this._http.put(url, data, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public patch(
    url: string,
    data?: any,
    config?: AxiosRequestConfig | undefined
  ) {
    return this._http.patch(url, data, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public delete(url: string, config?: AxiosRequestConfig | undefined) {
    return this._http.delete(url, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public head(url: string, config?: AxiosRequestConfig | undefined) {
    return this._http.head(url, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public options(url: string, config?: AxiosRequestConfig | undefined) {
    return this._http.options(url, config);
  }

  /**
   *
   */
  private interceptorsRequest(): void {
    this._http.interceptors.request.use(async (config: AxiosRequestConfig) => {
      try {
        const token = Cookie.get("token");
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
            ...config.headers,
          };
        }
        if (Object.keys(config.headers ?? {}).length) {
          config.headers = {
            ...config.headers,
          };
        }
        config.params = {
          ...config.params,
        };
      } catch (error) {
        console.log(error);
      }
      return config;
    });
  }

  /**
   *
   */
  private interceptorsResponse(): void {
    this._http.interceptors.response.use(
      (value: AxiosResponse) => value.data,
      async (error: AxiosError) => {
        const { response } = error;
        const config: any = error.config;

        if (
          response?.status === 406 &&
          !config._retry &&
          config.url !== AUTH_API.REFRESH_TOKEN
        ) {
          config._retry = true;
          let resultRefresh: { accessToken: string; refreshToken: string };
          try {
            resultRefresh = await this._http.post(
              AUTH_API.REFRESH_TOKEN,
              {
                refreshToken: Cookie.get("refreshToken"),
              },
              { headers: { Authorization: false } }
            );
            if (resultRefresh?.accessToken) {
              Cookies.set("token", resultRefresh.accessToken);
              Cookies.set("refreshToken", resultRefresh.refreshToken);
              config.headers = {
                ...config.headers,
                Authorization: `Bearer ${resultRefresh?.accessToken}`,
              };
            }
            return this._http(config);
          } catch (error) {
            Cookies.remove("token");
            Cookies.remove("refreshToken");
          }
        }

        if (Array.isArray((response as AxiosResponse)?.data?.error?.message)) {
          (response as AxiosResponse)?.data?.error?.message.map(
            ({ message }: { message: string }) =>
              notification.error({ message })
          );
        }

        return Promise.reject(error);
      }
    );
  }
}

export default new Http({
  baseURL: configs.BASE_API_URL_API,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
  withCredentials: false,
});

export const newEndpoint = new Http({
  baseURL: configs.BASE_API_URL_API,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
  withCredentials: false,
});

export const sportServer = new Http({
  baseURL: configs.SPORT_DOMAIN || "http://66.42.56.135:8080",
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
  withCredentials: false,
});

/** @format */

import ContainerMain from "components/Container";
import { get } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "App";
import { useNavigate } from "react-router-dom";

const IframeWrapper = ({ url }: { url?: string }) => {
  const location = useLocation();
  const gameState = location.state;
  const navigate = useNavigate();

  const redirectUrl = url || get(gameState, "url");
  useEffect(() => {
    const handlePopState = (event: any) => {
      if (window.location.pathname === "/game-launch") {
        // Prevent iframe from navigating back
        event.stopPropagation();
        // Navigate the root window back
        history.back();
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      // window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  return (
    <ContainerMain>
      <div
        style={{
          backgroundColor: "#151312",
          width: "100%",
          overflow: "hidden",
          color: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <iframe
            title="This is a unique title"
            src={redirectUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            sandbox="allow-same-origin allow-scripts"
            allowFullScreen
          />
        </div>
      </div>
    </ContainerMain>
  );
};

export default IframeWrapper;

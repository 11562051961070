import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Button = ({ children, ...rest }: any) => {
  const className = classNames(styles.wrapBtn, rest?.className);
  return (
    <>
      <button {...rest} className={className}>
        {children}
      </button>
    </>
  );
};

export default Button;

/** @format */

import React from "react";
import styles from "./styles.module.scss";

export const BannerCasino = () => {
  return (
    <>
      <div className={styles.wrapBannerCasino}>
        <div id="DIV_1">
          <div id="DIV_2">
            <div id="DIV_3">
              <div id="DIV_4">
                <img
                  src="/img/banner/game-bai/banner.jpg"
                  alt="Banner Game Bai"
                  id="IMG_5"
                />
              </div>
              <div id="DIV_6">
                {/* <img
                  src="/img/banner/game-bai/money.png"
                  alt="Money"
                  id="IMG_7"
                /> */}
              </div>
              <div id="DIV_8">
                <div id="DIV_9">
                  {/* <img
                    src="/img/banner/game-bai/model-1.png"
                    alt="Model"
                    id="IMG_10"
                  />
                  <img
                    src="/img/banner/game-bai/model-2.png"
                    alt="Model"
                    id="IMG_11"
                  /> */}
                </div>
              </div>
              <div id="DIV_12">
                {/* <img
                  src="/img/banner/game-bai/sanh-game-bai.png"
                  alt="Logo"
                  id="IMG_13"
                /> */}
              </div>
              <div id="DIV_14">
                {/* <img
                  src="/img/banner/game-bai/button.png"
                  alt="Button"
                  id="IMG_15"
                /> */}
              </div>
              <div id="DIV_16">
                <div id="DIV_17">
                  <div id="DIV_18">
                    <div id="DIV_19">
                      {/* <img src="/img/pocker.png" alt="Pocker" id="IMG_20" /> */}
                    </div>
                    <div id="DIV_21">
                      {/* <img
                        src="/img/tien-len-mien-nam.png"
                        alt="Pocker"
                        id="IMG_22"
                      /> */}
                    </div>
                    <div id="DIV_23">
                      {/* <img src="/img/texas.png" alt="Pocker" id="IMG_24" /> */}
                    </div>
                    <div id="DIV_25">
                      {/* <img src="/img/mau-binh.png" alt="Pocker" id="IMG_26" /> */}
                    </div>
                  </div>
                  <div id="DIV_27">
                    <div id="DIV_28">
                      <img src="/img/aviator.png" alt="Pocker" id="IMG_29" />
                    </div>
                    <div id="DIV_30">
                      <img src="/img/bau-cua.png" alt="Pocker" id="IMG_31" />
                    </div>
                    <div id="DIV_32">
                      <img src="/img/xoc-dia.png" alt="Pocker" id="IMG_33" />
                    </div>
                    <div id="DIV_34">
                      <img
                        src="/img/thi-tran-ma-quai.png"
                        alt="Pocker"
                        id="IMG_35"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapBannerCasinoDesktop}>
        <div id="DIV_1">
          <div id="DIV_2">
            <div id="DIV_3">
              <div id="DIV_4">
                <img
                  src="/img/banner/game-bai/banner.jpg"
                  alt="Banner Game Bai"
                  id="IMG_5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import moment from "moment";
import Http, { sendGet, sendPost } from "./axios";
import { compileUrl } from "../utils/helper";

// eslint-disable-next-line import/prefer-default-export
export const listBank = (payload?: any) => Http.get("/user/banks", payload);
export const getCoin = () => Http.get("/user/coin");
export const getMethods = () => Http.get("/user/method");
export const getOTP = () => Http.get(`user/otp`);
export const getTransferHistory = (options?: ValueOptions) => {
  return Http.get(
    compileUrl("user/transfer/history", options?.params),
    options
  );
};

export const getTransferHistory2 = ({ page, pageSize, username }: any, config?: any) =>
    Http.get(
        `user/transfer/history?page=${page}&pageSize=${pageSize}${
            username ? `&username=${username}` : ""
        }`,
        config
    );


export const transferCoin = (payload?: any) =>
  Http.post("/user/transfer", payload);

// export const placeABet = (payload?: any) => sendPost('/api/odds/sport_books', payload);
export const addBank = (payload?: any) => Http.post("/user/banks/add", payload);

export const withDraw = (payload?: any) => Http.post("user/withdraw", payload);

export const transactionHistory = ({ page, pageSize }: any) =>
  Http.get(`user/transaction/history?page=${page}&pageSize=${pageSize}`);

export const coinHistory = ({ page, pageSize, username }: any, config?: any) =>
  Http.get(
    `user/coin/history?page=${page}&pageSize=${pageSize}${
      username ? `&username=${username}` : ""
    }`,
    config
  );

export const listUserLower = ({
  page,
  pageSize,
  date = moment().format("MM-YYYY"),
}: any) =>
  Http.get(`user/downlevel?page=${page}&pageSize=${pageSize}&month=${date}`);
export const listUserDownlevel = ({
  page,
  pageSize,
  date = moment().format("MM-YYYY"),
}: any) =>
  Http.get(`user/downlevel2?page=${page}&pageSize=${pageSize}&month=${date}`);

export const postGenerateQRBank = (payload?: any, config?: any) =>
  Http.post(`https://api.vietqr.io/v2/generate`, payload, config);

/** @format */

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getTransferHistory } from "../../../api/profile";

import { get } from "lodash";
import { render } from "@testing-library/react";
import moment from "moment";
import { formatAmount } from "../../../utils/helper";
import { getTransferHistory as getTransferHistoryAdmin } from "../../../api/admin";
import {useAuth} from "../../../utils/hooks/useAuth";

function TableHistory({ username }: { username?: string }) {
  const [data, setData] = useState([

  ]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const onChange = (page: any, pageSize: any) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const {setIsLoading} = useAuth();
  useEffect(() => {
    setIsLoading(true);
    (username
      ? getTransferHistoryAdmin({
          params: {
            page: page - 1,
            pageSize: pageSize,
            ...(username ? { username } : {}),
          },
        })
      : getTransferHistory({
          params: {
            page: page - 1,
            pageSize: pageSize,
            ...(username ? { username } : {}),
          },
        })
    )
      .then((res) => {
        setTotal(get(res, "totalItems", 0));
        setData(get(res, "data", []));
        setIsLoading(false);
      })
      .catch((err) => {
        setTotal(0);
        setData([]);
        setIsLoading(false);
      });
  }, []);

  const columns = [
    {
      title: "Người Gửi",
      dataIndex: "fromUsername",
      key: "fromUsername",
    },
    {
      title: "Người Nhận",
      dataIndex: "toUsername",
      key: "toUsername",
    },
    {
      title: "Số tiền",
      dataIndex: "coin",
      key: "coin",
      render: (value: number) => {
        return formatAmount(value);
      },
    },
    {
      title: "Thời gian",
      dataIndex: "date",
      key: "date",
      render: (value: string) => {
        return moment(value).format("DD/MM/YYYY HH:mm");
      },
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      onChange={(pagination) => {
        onChange(pagination.current, pagination.pageSize);
      }}
      pagination={{
        pageSize: pageSize,
        pageSizeOptions: [5, 10],
        current: page,
        total,
      }}
      className="custom-table"
    />
  );
}

export default TableHistory;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */
.styles_wrapBtn__dKltS {
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  padding: 4px 19px;
  text-transform: uppercase;
  width: max-content;
  border-radius: 6px;
}
.styles_popover__ls--s .ant-popover-arrow {
  display: none !important;
}
.styles_popover__ls--s .styles_ant-popover-arrow__RYUju {
  display: none;
}

.styles_popoverOverlay__ItshE {
  padding-left: 0 !important;
}
.styles_popoverOverlay__ItshE .ant-popover-arrow {
  display: none !important;
}
.styles_popoverOverlay__ItshE .options-select {
  justify-content: flex-start !important;
  min-height: 32px !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/styles.module.scss"],"names":[],"mappings":"AAAA,aAAA;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AAAF;AAQI;EACE,wBAAA;AANN;AASE;EACE,aAAA;AAPJ;;AAWA;EACE,0BAAA;AARF;AAUI;EACE,wBAAA;AARN;AAUI;EACE,sCAAA;EACA,2BAAA;EACA,2BAAA;EACA,0BAAA;AARN","sourcesContent":["/** @format */\n\n.wrapBtn {\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 28px;\n  letter-spacing: 1px;\n  border: none;\n  cursor: pointer;\n  padding: 4px 19px;\n  text-transform: uppercase;\n  width: max-content;\n  border-radius: 6px;\n  :global {\n    \n  }\n}\n\n.popover {\n  :global {\n    .ant-popover-arrow {\n      display: none !important;\n    }\n  }\n  .ant-popover-arrow {\n    display: none;\n  }\n}\n\n.popoverOverlay {\n  padding-left: 0 !important;\n  :global {\n    .ant-popover-arrow {\n      display: none !important;\n    }\n    .options-select {\n      justify-content: flex-start !important;\n      min-height: 32px !important;\n      font-weight: 700 !important;\n      cursor: pointer !important;\n    }\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapBtn": `styles_wrapBtn__dKltS`,
	"popover": `styles_popover__ls--s`,
	"ant-popover-arrow": `styles_ant-popover-arrow__RYUju`,
	"popoverOverlay": `styles_popoverOverlay__ItshE`
};
export default ___CSS_LOADER_EXPORT___;

/** @format */

import {Form, InputNumber, Modal, Typography} from "antd";
import Button from "components/Button";
import {useEffect, useReducer, useState} from "react";
import {useModal} from "utils/hooks/useModal";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {ModalNotifications} from "./ModalNotifications";
import {
    getCoinMainAccount,
    getCoinUser,
    launchGame,
    transferMoney,
} from "api/gameLaunch";
import {AxiosError} from "axios";
import {ModalSignIn} from "./ModalSignIn";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import Loading from "components/Loading";
import {get} from "lodash";

type ModalNapRutTienProps = {
    message?: string;
    title?: string;
    action?: () => void;
    actionText?: string;
    productType?: number;
    gameCode?: string;
    platform?: string;
};

const GameMapping = [
    {
        productType: "OBSB",
        label: "OBSB",
        productCode: "131",
    },
    {
        productType: "BB",
        label: "BBin Sport/gaming",
        productCode: "79",
    },
    {
        productType: "SABA",
        label: "Saba Sport",
        productCode: "174",
    },
    {
        productType: "SEX",
        label: "Sex Gaming",
        productCode: "112",
    },
];

interface IResetPassword {
    coin: number;
}

export const ModalNapRutTien = ({
                                    message,
                                    title,
                                    productType,
                                    gameCode,
                                    platform,
                                }: ModalNapRutTienProps) => {
    const {hideModal, showModal} = useModal();
    const [form] = Form.useForm<IResetPassword>();
    const [loadings, setLoadings] = useState<boolean[]>([]);
    const [states, setStates] = useReducer((o: any, n: any) => ({...o, ...n}), {
        coinFromGame: "",
        myCoin: "",
        linkGame: "",
        messageNoti: "",
    });

    const onFinish = (values: IResetPassword) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = true;
            return newLoadings;
        });
        setTimeout(() => {
            transferMoney({
                config: {
                    params: {
                        product_type: productType,
                        coin: values.coin,
                    },
                },
            })
                .then((res) => {
                    setLoadings((prevLoadings) => {
                        const newLoadings = [...prevLoadings];
                        newLoadings[1] = false;
                        return newLoadings;
                    });
                    showModal(ModalNotifications, {message: "Nạp tiền thành công"});
                })
                .catch((error) => {
                    setLoadings((prevLoadings) => {
                        const newLoadings = [...prevLoadings];
                        newLoadings[1] = false;
                        return newLoadings;
                    });
                    showModal(ModalNotifications, {message: "Nạp tiền thất bại"});
                });
        }, 2000);
    };

    const handler = {
        clickLaunchGame: () => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[0] = true;
                return newLoadings;
            });
            if (states.linkGame) {
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[0] = false;
                    return newLoadings;
                });
                hideModal();
                // history.push("/game-launch", { url: states.linkGame });
                window.open(states.linkGame);
            } else {
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[0] = false;
                    return newLoadings;
                });
                hideModal();
                showModal(ModalNotifications, {
                    message: "Game đang bảo trì, vui lòng quay lại sau",
                });
            }
        },
    };

    const fnCommon = {
        getCoinUserFromGame: async () => {
            try {
                const coinFromGame = await getCoinUser({
                    config: {
                        params: {
                            product_type: productType,
                        },
                    },
                });
                return (coinFromGame as any)?.balance;
            } catch (err: any) {
                // if (error?.response?.status === 401) {
                //   throw error;
                // }
                showModal(ModalNotifications, {
                    message: get(err, "response.data.data") ||
                        "Game đang bảo trì, vui lòng quay lại sau",
                });
                return 0;
            }
        },
        getCoinUserMain: async () => {
            try {
                const mainCoin = await getCoinMainAccount();
                return (mainCoin as any).coin;
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    throw error;
                }
                return 0;
            }
        },
        getLinkGame: async () => {
            try {
                const result: any = await launchGame({
                    config: {
                        params: {
                            product_type: productType,
                            platform,
                            game_code: gameCode,
                            language: "VN",
                            game_mode: 1,
                        },
                    },
                });
                return result.game_url;
            } catch (err: any) {
                // if (err?.response?.status === 401) {
                //   throw err;
                // }
                showModal(ModalNotifications, {
                    message: get(err, "response.data.data") ||
                        "Game đang bảo trì, vui lòng quay lại sau",
                });
                return "";
            }
        },
    };

    useEffect(() => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[0] = true;
            return newLoadings;
        });
        Promise.all([
            fnCommon.getCoinUserFromGame(),
            fnCommon.getCoinUserMain(),
            fnCommon.getLinkGame(),
        ])
            .then((res) => {
                setStates({coinFromGame: res[0], myCoin: res[1], linkGame: res[2]});
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[0] = false;
                    return newLoadings;
                });
            })
            .catch((err: AxiosError) => {
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[0] = false;
                    return newLoadings;
                });
                hideModal();

                if (err.response?.status === 401) {
                    showModal(ModalSignIn, {title: "Vui lòng đăng nhập để chơi Game"});
                } else {
                    showModal(ModalNotifications, {
                        message: get(err, "response.data.data") ||
                            "Game đang bảo trì, vui lòng quay lại sau",
                    });
                }
            });
    }, []);
    return (
        <>
            <Modal
                title={"Chuyển Khoản Nhanh"}
                visible
                onCancel={() => {
                    hideModal();
                }}
                bodyStyle={{minHeight: 200}}
                footer={false}
                wrapClassName={classNames(styles.wrapModal)}
                centered={true}
            >
                {!loadings[0] ? (
                    states.myCoin !== "" ? (
                        <>
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                                style={{width: "100%"}}
                            >
                                <Form.Item
                                    label="TK Chính"
                                    name="mainAccount"
                                    initialValue={states.myCoin}
                                >
                                    <Typography
                                        style={{
                                            color: "white",
                                        }}
                                    >
                                        {get(states, "myCoin", "0")
                                            ? String(get(states, "myCoin", "0"))?.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )
                                            : 0}
                                    </Typography>
                                </Form.Item>
                                <Form.Item
                                    label={
                                        GameMapping.find(
                                            (item) => item.productCode === productType?.toString()
                                        )?.label
                                    }
                                    name={"gameName"}
                                    initialValue={states.coinFromGame}
                                >
                                    <Typography
                                        style={{
                                            color: "white",
                                        }}
                                    >
                                        {get(states, "coinFromGame", "0")
                                            ? String(get(states, "coinFromGame", "0"))?.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )
                                            : 0}
                                    </Typography>
                                </Form.Item>
                                <Form.Item label="Điểm chuyển" name="coin">
                                    <InputNumber
                                        width={"100%"}
                                        placeholder="Nhập coin"
                                        formatter={(value) =>
                                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                    />
                                </Form.Item>
                                <Form.Item className="text-center">
                                    <Button type="submit">
                                        {loadings[1] ? <Loading3QuartersOutlined spin/> : "Gửi"}
                                    </Button>
                                </Form.Item>
                                <Form.Item className="text-center">
                                    <Button type="button" onClick={handler.clickLaunchGame}>
                                        Chơi Game
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    ) : null
                ) : (
                    <Loading/>
                )}
            </Modal>
        </>
    );
};

/** @format */

import { Modal, Typography } from "antd";
import { ModalResetPassword } from "components/Modal/ModalResetPassword";
import React from "react";

export const ResetPassword = () => {
  const { Title } = Typography;
  return (
    <>
      <ModalResetPassword />
    </>
  );
};

/** @format */
import Http from "api/axios";
import { AUTH_API } from "utils/definitions/api";
import { compileUrl } from "utils/helper";
import { sendPost } from "./axios";
import { AxiosRequestConfig } from "axios";

export const signin = (payload: any) => sendPost("/api/auth/signin", payload);

export const signUp = (options?: ValueOptions | undefined) =>
  Http.post(compileUrl(AUTH_API.SIGN_UP), options?.data);

export const signIn = (options?: ValueOptions | undefined) =>
  Http.post(compileUrl(AUTH_API.SIGN_IN), options?.data, options?.config);

export const sendMailResetPassword = (options?: ValueOptions | undefined) =>
  Http.post(compileUrl(AUTH_API.SEND_MAIL_RESET_PASSWORD), options?.data);

export const sendResetPassword = (options?: ValueOptions | undefined) =>
  Http.post(compileUrl(AUTH_API.RESET_PASSWORD), options?.data);

export const signOut = (
  options?: ValueOptions | undefined,
  config?: AxiosRequestConfig<any> | undefined
) => Http.post(compileUrl(AUTH_API.LOG_OUT), options?.data, config);

export const getUserInfo = (options?: ValueOptions | undefined) =>
  Http.get(compileUrl(AUTH_API.USER_INFO), options?.config);

export const registrationConfirm = (options?: ValueOptions | undefined) =>
    Http.sendPost(compileUrl(AUTH_API.USER_REGISTRATION_CONFIRM), options?.data);

export const changePassword = (options?: ValueOptions | undefined) =>
  Http.sendPost(compileUrl(AUTH_API.UPDATE_PASSWORD), options?.data);

/** @format */

import { Modal } from "antd";
import React from "react";
import { useModal } from "utils/hooks/useModal";
import classNames from "classnames";
import styles from "./styles.module.scss";

type ModalNotificationsProps = {
  message: string;
  title?: string;
  action?: () => void;
  actionText?: string;
};

export const ModalNotifications = ({
  message,
  title,
  action,
  actionText,
}: ModalNotificationsProps) => {
  const { hideModal } = useModal();
  return (
    <>
      <Modal
        title={title || "Thông Báo"}
        visible
        onCancel={() => {
          hideModal();
        }}
        footer={false}
        wrapClassName={classNames(styles.wrapModal)}
        centered={true}
      >
        <p>{message}</p>
        {action && actionText ? (
          <span
            onClick={() => {
              hideModal();
              action();
            }}
            className={classNames(styles.action)}
          >
            {actionText}
          </span>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

/** @format */

import { ContractItem } from "components/Card/ContractItem/ContractItem";
import { ContractItem2 } from "components/Card/ContractItem/ContractItem2";
import { About } from "../../pages/Home/About/About";
import styles from "./styles.module.scss";
import { Col, Row } from "antd";
import configs from "../../constants/config";
export const Footer = () => {
  return (
    <div className={styles.wrapFooter}>
      {/* <divs */}
      <About />
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        {/* <FooterInformation />
        <FooterInformation /> */}
        <div className="contact">
          {configs.URL_CSKH || configs.URL_TELE ? (
            <>
              <h2 id="H2_1">LIÊN HỆ</h2>
              <div className={styles.contractList}>
                {Array(1)
                  .fill(null)
                  .map((item, index) => (
                    <Row>
                      <Col>
                        <ContractItem />
                      </Col>
                      <Col>
                        <ContractItem2 />
                      </Col>
                    </Row>
                  ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

/** @format */

import React, { FC } from "react";
import classNames from "classnames";

import logo from "assets/Header/logo.svg";
import configs from "constants/config";

import styles from "./styles.module.scss";

import RightHeader from "./RightHeader";
import { history } from "App";

type IPageHeader = {
  handlerSideBar?: () => void;
};
export default function PageHeader({ handlerSideBar }: IPageHeader) {
  const handler = {
    navigatorToHome: () => {
      history.push("/");
    },
  };

  return (
    <div className={classNames(styles.wrapHeader)}>
      <div className={styles.wraplogo}>
        <div id="DIV_1" onClick={handlerSideBar}>
          <img src="/img/icon-menu.svg" alt="icon" id="IMG_2" />
        </div>
        <div onClick={handler.navigatorToHome} style={{ cursor: "pointer" }}>
          <img src={configs.LOGO || logo} alt="logo-web" />
        </div>
      </div>
      <RightHeader />
    </div>
  );
}

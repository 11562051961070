/** @format */

import React, { PropsWithChildren } from "react";

export const ModalContext = React.createContext<ModalContextProps>({
  component: null,
  props: {},
  // tslint:disable-next-line:no-empty
  showModal: () => {},
  // tslint:disable-next-line:no-empty
  hideModal: () => {},
});

export const ModalProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const showModal = (component: any, props: any) => {
    setModal({
      props,
      component,
      showModal,
      hideModal,
    });
  };
  const hideModal = () => {
    setModal({ component: null, props: {}, showModal, hideModal });
  };
  const [modal, setModal] = React.useReducer(
    (o: ModalContextProps, n: ModalContextProps) => ({ ...o, ...n }),
    {
      props: {},
      component: null,
      showModal,
      hideModal,
    } as ModalContextProps
  );

  const value = React.useMemo(() => modal, [modal]);

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const ModalRoot: React.FunctionComponent = () => (
  <ModalContext.Consumer>
    {({ component: Component, props, hideModal }: ModalContextProps) =>
      Component ? <Component {...props} onClose={hideModal} /> : null
    }
  </ModalContext.Consumer>
);

export const useModal = () => {
  const { showModal, hideModal} = React.useContext(ModalContext);
  return { showModal, hideModal };
};

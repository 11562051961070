/** @format */

import { ModalNapRutTien } from "components/Modal/ModalNapRutTien";
import { ModalSignIn } from "components/Modal/ModalSignIn";
import React, { FC } from "react";
import { useAuth } from "utils/hooks/useAuth";
import { useModal } from "utils/hooks/useModal";
import styles from "./styles.module.scss";

export type CardGameItemProps = {
  displayStatus: number;
  gameType: string;
  gameName: string;
  tcgGameCode: string;
  productCode: string;
  productType: number;
  platform: string;
  gameSubType: string;
  trialSupport: string;
  imageUrl: string;
  imageSupplier?: string;
};

export const CardGameItem: FC<{ item: CardGameItemProps }> = ({ item }) => {
  const { showModal } = useModal();
  const { token } = useAuth();
  const handler = {
    onLaunchGame: () => {
      token
        ? showModal(ModalNapRutTien, {
            productType: item.productType,
            gameCode: item.tcgGameCode,
            platform: window.innerWidth < 768 ? "html5" : "html5-desktop",
            title: item.gameName,
          })
        : showModal(ModalSignIn, {
            title: "Vui lòng đăng nhập để tiếp tục",
          });
    },
  };

  return (
    <div className={styles.wrapCardGameItem}>
      <div id="DIV_1">
        <div id="DIV_2">
          <a onClick={handler.onLaunchGame} id="A_3">
            <img
              alt={item.gameName}
              src={`/img/games/${item.tcgGameCode}.png`}
              id="IMG_4"
            />
          </a>
          <div>
            <img src={item.imageSupplier} id="IMG_logo" />
          </div>
          <div id="DIV_5">
            <div id="DIV_6">
              <div id="DIV_7">{item.gameName}</div>
            </div>
            <div id="DIV_8">
              <div id="DIV_9">
                <img src="/img/icon-play.svg" alt="Play" id="IMG_10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/** @format */

import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

interface ListMenuI {
  urlPage: string;
  labelPage: string;
  isHot?: boolean;
  isNew?: boolean;
  isLive?: boolean;
  state?: string;
}

const LIST_MENU: ListMenuI[] = [
  {
    urlPage: "/",
    labelPage: "Trang chủ",
    isHot: true,
  },
  {
    urlPage: "/thethao",
    labelPage: "Thể thao",
  },
  {
    urlPage: "/lode",
    labelPage: "Lô đề",
    isNew: false,
  },
  {
    urlPage: "/list-game",
    labelPage: "Casino",
    isLive: true,
    state: "LIVE_CASINO",
  },
  {
    urlPage: "/list-game",
    labelPage: "Bacarat",
    state: "BACCARAT",
  },
  {
    urlPage: "/list-game",
    labelPage: "ĐÁ GÀ",
    isNew: true,
    isLive: true,
    state: "COCK_FLIGHT",
  },
  {
    urlPage: "/list-game",
    labelPage: "nổ hũ",
    state: "NO_HU",
  },

  // // ! update URL
  // {
  //   urlPage: "/e-sport",
  //   labelPage: "Thể thao ảo",
  //   // isNew: true,
  // },

  // // ! update URL
  // {
  //   urlPage: "/e-sport",
  //   labelPage: "Number Game",
  //   // isNew: true,
  // },
  // {
  //   urlPage: "/list-game",
  //   labelPage: "bắn cá",
  //   state: "BAN_CA",
  // },
  {
    urlPage: "/list-game",
    labelPage: "xóc đĩa",
    state: "XOC_DIA",
  },
];

const HeaderItem = ({
  labelPage,
  isLive,
  isNew,
  isHot,
}: {
  labelPage?: string;
  isLive?: boolean;
  isNew?: boolean;
  isHot?: boolean;
}) => {
  return (
    <div className={styles.wrapMenuItem}>
      {isHot && <span className="menu-live">hot</span>}
      {isLive && <span className="menu-live">live</span>}
      {labelPage}
      {isNew && <span className="menu-note">NEW</span>}
    </div>
  );
};

const HeaderMenu = () => {
  const [indexSelect, setIndexSelect] = useState<number>(0);
  const location = useLocation();
  useEffect(() => {
    setIndexSelect(
      LIST_MENU.findIndex(
        (item) =>
          item?.state === (location.state as any)?.gameType &&
          item.urlPage === location.pathname
      )
    );
  }, [location]);

  return (
    <>
      <div className={styles.wrapMenu}>
        <div id="DIV_1">
          {LIST_MENU.map((item, index) => (
            <div
              style={{ flex: 1 }}
              key={index}
              onClick={() => setIndexSelect(index)}
              className={
                indexSelect === index ? "animated activeNav" : "animated"
              }
            >
              <NavLink to={item.urlPage} state={{ gameType: item.state }}>
                <HeaderItem
                  labelPage={item.labelPage}
                  isLive={item.isLive}
                  isNew={item.isNew}
                  isHot={item.isHot}
                />
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HeaderMenu;

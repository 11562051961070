import React from "react";
import { Modal } from "antd";
import classNames from "classnames";

import RegisterForm from "..";

import styles from './styles.module.scss'

interface IProps {
  visible: boolean;
  onCloseModal: () => void;
}
const ModalRegister = ({ visible, onCloseModal }: IProps) => {
  return (
    <>
      <Modal
        title="TẠO TÀI KHOẢN"
        visible={visible}
        onCancel={onCloseModal}
        footer={false}
        wrapClassName={classNames(styles.wrapModalRegister)}
        centered={true}
      >
        <RegisterForm onCloseModal={onCloseModal} />
      </Modal>
    </>
  );
};

export default ModalRegister;

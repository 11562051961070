/** @format */

import Http from "api/axios";
import {AGENCY_API, GAME_LAUNCH} from "utils/definitions/api";
import {compileUrl} from "utils/helper";
import {sendPost} from "./axios";

export const getCoinUser = (options?: ValueOptions | undefined) =>
    Http.get(compileUrl(GAME_LAUNCH.GET_COIN, options?.params), options?.config);

export const transferMoney = (options?: ValueOptions | undefined) =>
    Http.get(
        compileUrl(GAME_LAUNCH.TRANSFER_MONEY, options?.params),
        options?.config
    );

export const launchGame = (options?: ValueOptions | undefined) =>
    Http.get(
        compileUrl(GAME_LAUNCH.LAUNCH_GAME, options?.params),
        options?.config
    );

export const getListGame = (options?: ValueOptions | undefined) =>
    Http.get(
        compileUrl(GAME_LAUNCH.GET_LIST_GAME, options?.params),
        options?.config
    );

export const getCoinAllGame = (options?: ValueOptions | undefined) =>
    Http.get(
        compileUrl(GAME_LAUNCH.GET_COIN_ALL_GAME, options?.params),
        options?.config
    );

export const refundCoinFromGame = (options?: ValueOptions | undefined) =>
    Http.get(
        compileUrl(GAME_LAUNCH.REFUND_ONE_GAME, options?.params),
        options?.config
    );

export const getCoinMainAccount = (options?: ValueOptions | undefined) =>
    Http.get(compileUrl(GAME_LAUNCH.USER_COIN, options?.params), options?.config);

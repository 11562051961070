/** @format */
/** @format */

import {Button, Form, Input, InputNumber, Modal, Typography} from "antd";
import React, {useState} from "react";
import {useModal} from "utils/hooks/useModal";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {agencyCreateUser} from "../../api/agency";
import {get} from "lodash";
import {ModalNotifications} from "./ModalNotifications";
import {transferMoney} from "../../api/gameLaunch";
import Loading from "components/Loading";
import {useAuth} from "../../utils/hooks/useAuth";

// type ModalAgencyCreateUserProps = {
//     username?: string;
//     password?: string;
// };

type IProps = {
    visible: boolean;
    onCloseModal: (res?: any) => void;
};

export const ModalAgencyCreateUser = ({ visible, onCloseModal }: IProps)  => {
    const {hideModal, showModal} = useModal();
    const [loadings, setLoadings] = useState<boolean[]>([]);
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    const {setIsLoading} = useAuth();

    const fnCommon = {
        agencyCreateUser: async () => {
            setIsLoading(true)
            try {
                const result: any = await agencyCreateUser({
                    config: {
                        username: username,
                        password: password,
                    },
                });
                showModal(ModalNotifications, {
                    message: get(result.data, "response.data.data") ||
                        "Thành Công",
                });
                setIsLoading(false);
                onCloseModal()
                return result.data;
            } catch (err: any) {
                if (err?.response?.status === 401) {
                    throw err;
                }
                showModal(ModalNotifications, {
                    message: get(err, "response.data.data") ||
                        "Không thể tạo tài khoản, vui lòng liên hệ người giới thiệu để biết thêm chi tiết",
                });
                setIsLoading(false);
                onCloseModal()
                return "";
            }
        },
    };
    // setLoadings((prevLoadings) => {
    //     const newLoadings = [...prevLoadings];
    //     newLoadings[1] = true;
    //     return newLoadings;
    // });
    return (
        <>
            <Modal
                title={"Tạo tài khoản người dùng"}
                visible={visible}
                onCancel={() => {
                    hideModal();
                    onCloseModal();
                }}
                footer={
                    <>
                        <Button
                            type="primary"
                            onClick={fnCommon.agencyCreateUser}>
                            OK
                        </Button>
                        <Button onClick={() => {
                            onCloseModal("CANCEL")
                        }}>Cancel</Button>
                    </>
                }
                wrapClassName={classNames(styles.wrapModal)}
                centered={true}>
                <Form layout="horizontal" style={{width: "100%"}}>
                    <Form.Item
                        label="Tên tài khoản"
                        labelCol={{span: 8}}
                        wrapperCol={{span: 10}}>
                        <Input
                            placeholder="Nhập tên tài khoản"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Mật khẩu"
                        labelCol={{span: 8}}
                        wrapperCol={{span: 10}}>
                        <Input

                            placeholder="Nhập mật khẩu"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

/** @format */

import configs from "constants/config";
import React from "react";
import styles from "./styles.module.scss";

export const About = () => {
  return (
    <div className={styles.wrapAbout}>
      <div id="DIV_1">
        <div
          id="DIV_2"
          style={{
            padding: "0px 12px",
            height: "auto",
            width: "auto",
          }}
        >
          GIỚI THIỆU {configs.HOST_NAME || "Bong68"}
        </div>
        <p
          id="P_3"
          style={{
            padding: "0px 12px",
            height: "auto",
            width: "auto",
          }}
        >
          {configs.HOST_NAME || "Bong68"} là website cá cược trực tuyến hàng đầu
          Châu Âu, chúng tôi mang đến những trải nghiệm tuyệt vời nhất cùng với
          tỷ lệ cược thể thao cạnh tranh, các trò chơi sòng bạc vô cùng phong
          phú và đặc sắc. Với tiêu chí phục vụ tốt nhất cho khách hàng, nhiều
          hình thức hỗ trợ ưu tiên nhất, chúng tôi cố gắng cung cấp cho quý
          khách hàng những trò chơi tinh tế và phong phú nhất, cùng với việc thu
          phí ưu đãi nhất cũng như các trò chơi có thưởng hấp dẫn để đền đáp sự
          ủng hộ của khách hàng.
        </p>
      </div>
    </div>
  );
};

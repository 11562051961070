/** @format */
/** @format */

import { Button, Form, InputNumber, Modal, Typography } from "antd";
import React from "react";
import { useModal } from "utils/hooks/useModal";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { setAgency } from "../../api/admin";
import { get } from "lodash";
function ModalAgency({
  username,
  agencyPercent = 0,
}: {
  username: string;
  agencyPercent: number;
}) {
  const { hideModal, showModal } = useModal();

  const [percent, setPercent] = React.useState(agencyPercent);
  const onChange = (value: any) => {
    setPercent(value);
  };
  return (
    <>
      <Modal
        title={"Phần Trăm Agency"}
        visible
        onCancel={() => {
          hideModal();
        }}
        footer={
          <>
            <Button
              type="primary"
              onClick={() => {
                setAgency({ username, percent })
                  .then(() => {
                    hideModal();
                    Modal.success({ content: "Update agency success" });
                  })
                  .catch((err) => {
                    Modal.error({
                      content:
                        get(err, "response.data.data") || "Update agency fail",
                    });
                  });
              }}
            >
              OK
            </Button>
            <Button onClick={hideModal}>Cancel</Button>
          </>
        }
        wrapClassName={classNames(styles.wrapModal)}
        centered={true}
      >
        <Form layout="horizontal" style={{ width: "100%" }}>
          <Form.Item
            label="Username"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
          >
            <Typography style={{ color: "white" }}>test</Typography>
          </Form.Item>
          <Form.Item
            label="Phần trăm "
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
          >
            <InputNumber
              min={0}
              defaultValue={percent}
              max={100}
              onChange={(value) => onChange(value)}
              formatter={(value) => {
                return `${value}%`;
              }}
              parser={(value: any) => value.replace("%", "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export { ModalAgency };

/** @format */

import {AxiosRequestConfig} from "axios";
import Http, {sendGet, sendPost} from "./axiosAdmin";
import {compileUrl} from "utils/helper";
import {AGENCY_API, AUTH_API} from "utils/definitions/api";

export const transactionHistory = (
    {page, pageSize, transactionType, username}: any,
    config?: any
) =>
    Http.get(
        `admin/transaction/history?page=${page}&pageSize=${pageSize}${
            transactionType ? `&transactionType=${transactionType}` : ""
        }${username ? `&username=${username}` : ""}`,
        config
    );

export const coinChangeLog = (
    {page, pageSize, transactionType, username}: any,
    config?: any
) =>
    Http.get(
        `admin/coin/history?page=${page}&pageSize=${pageSize}${
            transactionType ? `&transactionType=${transactionType}` : ""
        }${username ? `&username=${username}` : ""}`,
        config
    );

export const getTransferHistoryAdmin = (
    {page, pageSize, transactionType, username}: any,
    config?: any
) =>
    Http.get(
        `admin/transfer/history?page=${page}&pageSize=${pageSize}${
            transactionType ? `&transactionType=${transactionType}` : ""
        }${username ? `&username=${username}` : ""}`,
        config
    );

export const getTransferHistory = (options?: ValueOptions) => {
    return Http.get(
        compileUrl("user/transfer/history", options?.params),
        options
    );
};

export const confirmTransaction = (payload: any, config?: any) =>
    Http.post(`admin/transaction/confirm`, payload, config);

export const cancelTransaction = (payload: any, config?: any) =>
    Http.post(`admin/transaction/cancel`, payload, config);

export const postTransaction = (payload: any, config?: any) =>
    Http.post("admin/fund", payload, config);

export const statistical = (config?: any) =>
    Http.get("admin/statistical", config);

export const getMaintain = (config?: any) => Http.get("admin/maintain", config);

export const postMaintain = (payload: any, config?: any) =>
    Http.post("admin/maintain", payload, config);

export const getListUser = (payload: any, config?: any) =>
    Http.get(
        `admin/user/list?page=${payload.page}&pageSize=${payload.pageSize}${
            payload.username ? `&username=${payload.username}` : ""
        }`,
        config
    );
export const getListAgency = (payload: any, config?: any) =>
    Http.get(
        `admin/user/list_agency?page=${payload.page}&pageSize=${payload.pageSize}${
            payload.username ? `&username=${payload.username}` : ""
        }`,
        config
    );

export const setAgency = (payload: any, config?: any) =>
    Http.post(`admin/agency_percent`, payload, config);
export const lockUser = (payload: any, config?: any) =>
    Http.post(`admin/lock`, payload, config);

export const onlySports = (payload: any, config?: any) =>
    Http.post(`admin/only_sports`, payload, config);

export const getListMethod = (config?: any) =>
    Http.get(`admin/method/list`, config);

export const updateMethod = (payload: any, config?: any) =>
    Http.post(`admin/method/update`, payload, config);

export const signOut = (
    options?: ValueOptions | undefined,
    config?: AxiosRequestConfig<any> | undefined
) => Http.post(compileUrl(AUTH_API.LOG_OUT), options?.data, config);

export const coinHistory = ({page, pageSize, username}: any, config?: any) =>
    Http.get(
        `user/coin/history?page=${page}&pageSize=${pageSize}${
            username ? `&username=${username}` : ""
        }`,
        config
    );

// export const getBalanceAll = (options?: ValueOptions | undefined) =>
//     // Http.get("game/balance/all", config);
//     Http.get(
//         compileUrl(AGENCY_API.AGENCY_TRANSFER),
//         options?.config
//     );


export const getBalanceAll = (options?: ValueOptions) => {
    return Http.get(
        compileUrl("game/balance/all", options?.params),
        options
    );
};

export const sportHistory = ({page, pageSize, username}: any, config?: any) =>
    Http.get(
        `user/sport/history?page=${page}&pageSize=${pageSize}${
            username ? `&username=${username}` : ""
        }`,
        config
    );

export const lotteryHistory2 = (
    {page, pageSize, username, day}: any,
    config?: any
) =>
    Http.get(
        `lottery/history?page=${page}&pageSize=${pageSize}${username ? `&username=${username}` : ""}${day ? `&day=${day}` : ""}`,
        config
    );

/** @format */

import { getCoinAllGame, getListGame } from "api/gameLaunch";
import { BannerCasino } from "components/Card/Banner/BannerCasino";
import {
  CardGameItem,
  CardGameItemProps,
} from "components/Card/CardGameItem/CardGameItem";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "utils/hooks/useAuth";
import styles from "./styles.module.scss";
import { get } from "lodash";
export const Casino = (props: any) => {
  const location = useLocation();
  const [listGame, setListGame] = useState<CardGameItemProps[]>([]);
  const { listImage } = useAuth();
  const fnCommon = {
    getListGameFromState: async () => {
      try {
        const listGameFromApi = await getListGame({
          config: {
            params: {
              gameType: get(location, "state.gameType") || props?.gameType,
            },
          },
        });
        return (listGameFromApi as any).games;
      } catch (err) {
        return [];
      }
    },
    getAllCoinInAllGame: async () => {
      try {
        const result: any = await getCoinAllGame();
        if (result?.data?.length) {
          return result?.data;
        }
        return [];
      } catch (error) {
        return [];
      }
    },
  };
  useEffect(() => {
    fnCommon.getListGameFromState().then((res) => {
      setListGame(res);
    });
  }, [location.state]);
  return (
    <div
      style={{
        backgroundColor: "#1d1d1d",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {!props?.gameType ? <BannerCasino /> : <></>}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ padding: "20px 15px", maxWidth: "1410px", width: "100%" }}
        >
          {/* <InputSearch /> */}
          <div className={styles.wrapContentCasino}>
            {listGame.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <CardGameItem
                    item={{
                      ...item,
                      imageSupplier: (
                        listImage?.find(
                          (el: any) => el.productType === item.productCode
                        ) as any
                      )?.imageUrl,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

/** @format */

import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import classNames from "classnames";

import styles from "./styles.module.scss";

// import Button from "components/Button";
import { signUp } from "api/authentication";
import { useModal } from "utils/hooks/useModal";
import { ModalRegisterConfirm } from "components/Modal";
import { history } from "App";
import { AxiosResponse } from "axios";

type IFormRegister = {
  user_name: string;
  password: string;
  phone: string;
  display_name: string;
  agency_name: string;
};

type IProps = {
  onCloseModal: () => void;
};

const RegisterForm = ({ onCloseModal }: IProps) => {
  const { showModal } = useModal();
  const [form] = Form.useForm<IFormRegister>();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: IFormRegister) => {
    setLoading(true);
    return signUp({
      data: {
        username: values.user_name,
        phoneNumber: values.phone,
        email: values.display_name,
        password: values.password,
        registerAsAdmin: false,
        agencyUsername: values.agency_name,
      },
    })
      .then((res) => {
        form.resetFields();
        setTimeout(() => {
          setLoading(false);
          onCloseModal();
          showModal(ModalRegisterConfirm, {
            message: (res as any)?.data,
            actionConfirm: () => history.push("/"),
          });
        }, 2000);
        onCloseModal();
        showModal(ModalRegisterConfirm, {
          message: (res as any)?.data,
          actionConfirm: () => history.push("/"),
        });
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          onCloseModal();
          showModal(ModalRegisterConfirm, {
            message: err.response?.data?.data,
            actionConfirm: () => history.push("/"),
            isError: true,
          });
        }, 2000);
      });
  };

  return (
    <>
      <div className={styles.wrapForm}>
        <Form
          name="login"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="TÊN ĐĂNG NHẬP"
            name="user_name"
            rules={[
              {
                pattern: /^[a-zA-Z0-9]*$/g,
                message:
                  "Tên đăng nhập cần tạo ít nhất 4 kí tự, chỉ chứa các chữ cái (A-Z,a-z) và chữ số (0-9). Không chứa các kí tự đặc biệt (!@#,...)",
              },
              {
                max: 12,
                message: "Tên đăng nhập cần tạo tối đa 12 kí tự",
              },
              {
                min: 4,
                message: "Tên đăng nhập cần tạo ít nhất 4 kí tự",
              },
            ]}
          >
            <Input
              placeholder="Tên đăng nhập cần tạo ít nhất 4 kí tự, chỉ chứa các chữ cái (A-Z,a-z) và chữ số (0-9). Không chứa các kí tự đặc biệt (!@#,...)"
              className={classNames(styles.inputBackGround)}
            />
          </Form.Item>
          <Form.Item label="MẬT KHẨU" name="password">
            <Input.Password
              placeholder="Mật khẩu 6 đến 32 ký tự"
              autoComplete="new-password"
              visibilityToggle={true}
            />
          </Form.Item>

          {/* <Form.Item label="SỐ ĐIỆN THOẠI" name="phone" className="mb-10">
            <Input placeholder="Số điện thoại" />
          </Form.Item>
          <div className={classNames(styles.textNote)}>
            Để nhận ngay tiền thưởng chào mừng, và các thông tin tài khoản, vui
            lòng điền số điện thoại liên lạc của quý khách chính xác.
          </div> */}
          <Form.Item label="Email" name="display_name" className="mb-10">
            <Input placeholder="Địa chỉ Email" />
          </Form.Item>
          <div className={classNames(styles.textNote)}>
            Vui lòng điền địa chỉ email
          </div>
          <Form.Item label="Người giới thiệu" name="agency_name">
            <Input placeholder="Mã đại lý (Nếu có)" />
          </Form.Item>

          <Form.Item className="text-center">
            <Button
              htmlType="submit"
              className={classNames(styles.btnRegister)}
              loading={loading}
            >
              ĐĂNG KÝ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default RegisterForm;

const configs = {
  HOST_NAME: process.env.REACT_APP_HOSTNAME,
  LOGO: process.env.REACT_APP_LOGO,
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  SPORT_DOMAIN: process.env.SPORT_API_DOMAIN,
  URL_CSKH: process.env.REACT_APP_URL_CSKH,
  URL_TELE: process.env.REACT_APP_URL_TELE,
  BASE_API_URL_API:
    process.env.REACT_APP_BASE_URL_API || "http://localhost:3004/test",
};

export default configs;

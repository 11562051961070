/** @format */

import { ModalNapRutTien } from "components/Modal/ModalNapRutTien";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useModal } from "utils/hooks/useModal";
import styles from "./styles.module.scss";
import logo from "assets/Header/logo.svg";
import { ContractItem } from "components/Card/ContractItem/ContractItem";
import { useAuth } from "utils/hooks/useAuth";
import { ModalSignIn } from "components/Modal/ModalSignIn";
import configs from "constants/config";
type ISideBarMobile = {
  onClose: () => void;
};
export const SideBarMobile: FC<ISideBarMobile> = ({ onClose }) => {
  const { showModal } = useModal();
  const { token } = useAuth();
  const listTheThao = [
    {
      url: "/img/sport/team/asia-mb.png",
      productType: 174,
      platform: "html5",
      gameCode: "SB0001",
      title: "A - Thể Thao",
    },
    {
      url: "/img/sport/team/s-sport-mb.png",
      productType: 131,
      platform: "html5",
      gameCode: "OBSB01",
      title: "S - Thể Thao",
    },
    // {
    //   url: "/img/sport/team/e-sport-mb.png",
    //   productType: 79,
    //   platform: "html5",
    //   gameCode: "BBB432",
    //   title: "E-SportHistory",
    // },

    {
      url: "/img/sport/team/e-sport-mb.png",
      productType: 79,
      platform: "html5",
      gameCode: "BBB432",
      title: "Thể Thao ảo",
    },
    {
      url: "/img/sport/team/e-sport-mb.png",
      productType: 79,
      platform: "html5",
      gameCode: "BBB432",
      title: "Number Game",
    },
    // {
    //   url: "/img/sport/team/latin-mb.png",
    //   productType: 79,
    //   platform: "html5",
    //   gameCode: "BBB386",
    //   title: "L - Thể Thao",
    // },
  ];

  const handler = {
    onClickTheThao: (item: any) => {
      token
        ? showModal(ModalNapRutTien, {
            productType: item.productType,
            gameCode: item.gameCode,
            platform: item.platform,
            title: item.title,
          })
        : showModal(ModalSignIn, {
            title: "Vui lòng đăng nhập để tiếp tục",
            url: "/",
          });
      onClose();
    },
  };

  return (
    <div className={styles.wrapSidebarMobile}>
      <div id="DIV_1">
        <div id="DIV_2">
          <div id="DIV_3" onClick={onClose}>
            <Link to="/">
              <img src={configs.LOGO || logo} alt="Logo" id="IMG_4" />
            </Link>
          </div>
          <div id="DIV_5">
            <div id="DIV_6">
              <img src="/img/tele.svg" alt="Mobile Icon" id="IMG_7" />
            </div>
            <div id="DIV_8">
              <a
                target="_blank"
                href="https://t.me/SV68CSKH"
                rel="nofollow"
                id="A_10"
              >
                <p id="P_9">Telegram</p> @{configs.HOST_NAME || "Bong68"}CSKH
              </a>
            </div>
          </div>
          <div id="DIV_11" onClick={onClose}>
            <img src="/img/nav-sidebar/close.svg" alt="Close" id="IMG_12" />
          </div>
        </div>
        <div id="DIV_13">
          <div id="DIV_14">
            <div id="DIV_15">
              <div id="DIV_16">Thể Thao</div>
              <div id="DIV_17">
                {listTheThao.map((item, index) => (
                  <>
                    <div
                      id="DIV_25"
                      key={index}
                      onClick={() => {
                        handler.onClickTheThao(item);
                      }}
                    >
                      <div id="DIV_26">
                        <div id="DIV_27">
                          <div id="DIV_28">
                            <div id="DIV_22">
                              <img
                                src="/img/nav-sidebar/sports.svg"
                                alt="Thể thao"
                                id="IMG_23"
                              />
                            </div>
                            <div id="DIV_31">{item.title}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                <Link to={"/lode"}>
                  <div id="DIV_25" onClick={onClose}>
                    <div id="DIV_26">
                      <div id="DIV_27">
                        <div id="DIV_28">
                          <div id="DIV_29">
                            <img
                              src="/img/nav-sidebar/lo-de.svg"
                              alt="E-Sports"
                              id="IMG_30"
                            />
                          </div>
                          <div id="DIV_31">Lô Đề</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to={"/list-game"} state={{ gameType: "LIVE_CASINO" }}>
                  <div id="DIV_42" onClick={onClose}>
                    <div id="DIV_43">
                      <div id="DIV_44">
                        <div id="DIV_45">
                          <div id="DIV_46">
                            <div id="DIV_47">
                              <img
                                src="/img/nav-sidebar/live-casino.svg"
                                alt="Live casino"
                                id="IMG_48"
                              />
                            </div>
                            <div id="DIV_49">Live Casino</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to={"/list-game"} state={{ gameType: "COCK_FLIGHT" }}>
                  <div id="DIV_42" onClick={onClose}>
                    <div id="DIV_43">
                      <div id="DIV_44">
                        <div id="DIV_45">
                          <div id="DIV_46">
                            <div id="DIV_47">
                              <img
                                  src="/img/nav-sidebar/live-casino.svg"
                                  alt="Live casino"
                                  id="IMG_48"
                              />
                            </div>
                            <div id="DIV_49">Đá Gà</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div id="DIV_15">
              <div id="DIV_16">Cổng Game</div>
              <div id="DIV_17">
                <Link
                  to={{ pathname: "/list-game" }}
                  state={{ gameType: "BACCARAT" }}
                >
                  <div id="DIV_18" onClick={onClose}>
                    <div id="DIV_19">
                      <div id="DIV_20">
                        <div id="DIV_21">
                          <div id="DIV_22">
                            <img
                              src="/img/nav-sidebar/in-games.svg"
                              alt="Thể thao"
                              id="IMG_23"
                            />
                          </div>
                          <div id="DIV_24">Baccarat</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to={"/list-game"} state={{ gameType: "NO_HU" }}>
                  <div id="DIV_25" onClick={onClose}>
                    <div id="DIV_26">
                      <div id="DIV_27">
                        <div id="DIV_28">
                          <div id="DIV_29">
                            <img
                              src="/img/nav-sidebar/no-hu.svg"
                              alt="E-Sports"
                              id="IMG_30"
                            />
                          </div>

                          <div id="DIV_31">Nổ Hũ</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                {/* <Link to={"/list-game"} state={{ gameType: "BAN_CA" }}>
                  <div id="DIV_25" onClick={onClose}>
                    <div id="DIV_26">
                      <div id="DIV_27">
                        <div id="DIV_28">
                          <div id="DIV_29">
                            <img
                              src="/img/nav-sidebar/ban-ca.svg"
                              alt="E-SportHistory"
                              id="IMG_30"
                            />
                          </div>
                          <div id="DIV_31">Bắn Cá</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link> */}
                <Link to={"/list-game"} state={{ gameType: "XOC_DIA" }}>
                  <div id="DIV_25" onClick={onClose}>
                    <div id="DIV_26">
                      <div id="DIV_27">
                        <div id="DIV_28">
                          <div id="DIV_29">
                            <img
                              src="/img/nav-sidebar/e-sports.svg"
                              alt="E-Sports"
                              id="IMG_30"
                            />
                          </div>
                          <div id="DIV_31">Xóc Đĩa</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/** @format */

import React from "react";
import styles from "./styles.module.scss";
import flag_vietnam from "assets/Home/flag-vietnam.png";
import configs from "constants/config";

export const ContractItem2 = () => {
  return (
    <div className={styles.wrapContractItem}>
      {configs.URL_CSKH ? (
        <div id="DIV_1">
          <div id="DIV_2">
            <div id="DIV_3">
              <a
                target="_blank"
                href={configs.URL_CSKH}
                rel="nofollow"
                id="A_4"
              >
                <img alt="mobile" src="/img/channel.svg" id="IMG_5" />
              </a>
            </div>
            <a target="_blank" href={configs.URL_CSKH} rel="nofollow" id="A_7">
              <div id="DIV_6">
                <div id="DIV_8">Kênh thông tin KM</div>
                <div id="DIV_10">
                  <img src={flag_vietnam} id="IMG_11" alt="" /> Hotline VN
                </div>
                <p id="P_12">
                  <span>@{configs.HOST_NAME || "Bong68"}FUN</span>
                </p>
              </div>
            </a>
          </div>
          <div id="DIV_14">
            <a target="_blank" rel="nofollow" href={configs.URL_CSKH} id="A_15">
              Gọi Ngay
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

/** @format */

import React, { useState } from "react";
import Cookies from "js-cookie";
import { Navigate, useOutlet } from "react-router-dom";

import styles from "./styles.module.scss";

import PageHeader from "components/PageHeader";
import SideBar from "components/SideBar";
import HeaderMenu from "components/SideBar/HeaderMenu";
import { MenuMobile } from "components/Menu/MenuMobile";
import { SideBarMobile } from "components/SideBar/SideBarMobile/SideBarMobile";
import { Footer } from "components/Footer/Footer";

export default function PageWrapper() {
  const outlet = useOutlet();
  const [openSideBar, setOpenSideBar] = useState<boolean>(false);
  const handler = {
    togglerSideBar: () => setOpenSideBar(!openSideBar),
  };
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.mainWrapper}>
        <PageHeader handlerSideBar={handler.togglerSideBar} />
        {openSideBar ? (
          <SideBarMobile onClose={() => setOpenSideBar(false)} />
        ) : null}
        <HeaderMenu />
        {/* <SideBar /> */}
        <div className={styles.pageContent}>{outlet}</div>
        <MenuMobile />
        <Footer />
      </div>
    </div>
  );
}

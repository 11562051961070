// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapModalRegister__tsSvF .ant-modal-content {
  background-color: #292929;
  border-radius: 6px;
}
.styles_wrapModalRegister__tsSvF .ant-modal-content .ant-modal-header {
  background-color: #292929;
  border-radius: 6px;
}
.styles_wrapModalRegister__tsSvF .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 32px;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .styles_wrapModalRegister__tsSvF .ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: 24px;
  }
}
.styles_wrapModalRegister__tsSvF .ant-modal-content .ant-modal-close span.ant-modal-close-x {
  color: #FFFFFF;
}
.styles_wrapModalRegister__tsSvF .ant-modal-content .ant-input-status-error, .styles_wrapModalRegister__tsSvF .ant-modal-content span.ant-input-affix-wrapper {
  background-color: #292929 !important;
}
.styles_wrapModalRegister__tsSvF .ant-modal-content span.ant-input-affix-wrapper {
  background-color: #3b3b3b !important;
}
.styles_wrapModalRegister__tsSvF .ant-modal-content span.ant-input-suffix span {
  color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/RegisterForm/ModalRegister/styles.module.scss"],"names":[],"mappings":"AAEM;EACE,yBAAA;EACA,kBAAA;AADR;AAGQ;EACE,yBAAA;EACA,kBAAA;AADV;AAEU;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AAAZ;AAEU;EACE;IACE,eAAA;EAAZ;AACF;AAIU;EACE,cAAA;AAFZ;AAMQ;EACC,oCAAA;AAJT;AAOQ;EACC,oCAAA;AALT;AASU;EACE,sBAAA;AAPZ","sourcesContent":[".wrapModalRegister {\n    :global {\n      .ant-modal-content {\n        background-color: #292929;\n        border-radius: 6px;\n  \n        .ant-modal-header {\n          background-color: #292929;\n          border-radius: 6px;\n          .ant-modal-title {\n            font-size: 32px;\n            color: #FFFFFF;\n            font-weight: 700;\n            text-align: center;\n          }\n          @media only screen and (max-width: 767px) {\n            .ant-modal-title {\n              font-size: 24px;\n            }\n          }\n        }\n        .ant-modal-close {\n          span.ant-modal-close-x {\n            color: #FFFFFF;\n          }\n        }\n        \n        .ant-input-status-error, span.ant-input-affix-wrapper {\n         background-color: #292929 !important;\n        }\n\n        span.ant-input-affix-wrapper {\n         background-color: #3b3b3b !important;\n          \n        }\n        span.ant-input-suffix {\n          span {\n            color: #fff !important;\n          }\n        }\n       \n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapModalRegister": `styles_wrapModalRegister__tsSvF`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProps {
    children: JSX.Element
}

const ContainerMain = ({ children }: IProps) => {
    return (
        <>
            <div className={classNames(styles.wrapContainer)}>
                { children }
            </div>
        </>
    )
}

export default ContainerMain
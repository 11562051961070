/** @format */

import { Image, Modal } from "antd";
import React from "react";
import { useModal } from "utils/hooks/useModal";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Typography } from "antd";

type ModalRegisterConfirmProps = {
  message: string;
  actionConfirm: () => void;
  isError?: boolean;
};

export const ModalRegisterConfirm = ({
  message,
  actionConfirm,
  isError,
}: ModalRegisterConfirmProps) => {
  const { Title } = Typography;
  const { hideModal } = useModal();
  return (
    <>
      <Modal
        visible
        onCancel={() => {
          actionConfirm();
          hideModal();
        }}
        footer={false}
        wrapClassName={classNames(styles.wrapModal)}
        centered={true}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title level={4} style={{ color: "#fff" }}>
            {message}
          </Title>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={
              isError
                ? require("../../assets/Header/notified-error.c065ac3.png")
                : require("../../assets/Header/notified-success.134fb62.png")
            }
          />
        </div>
      </Modal>
    </>
  );
};

import React from "react";
import { Modal } from "antd";
import classNames from "classnames";

import styles from './styles.module.scss'
import LoginForm from "..";

interface IProps {
  visible: boolean;
  onCloseModal: () => void;
}
const ModalLogin = ({ visible, onCloseModal }: IProps) => {
  return (
    <>
      <Modal
        title="ĐĂNG NHẬP"
        visible={visible}
        onCancel={onCloseModal}
        footer={false}
        wrapClassName={classNames(styles.wrapModalRegister)}
        centered={true}
      >
        <LoginForm
          labelHas={true}
          isMobile={false}
          layout={"vertical"}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </>
  );
};

export default ModalLogin;

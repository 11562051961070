/** @format */

export const AUTH_API = {
  SIGN_UP: "/auth/register",
  SIGN_IN: "/auth/login",
  SEND_MAIL_RESET_PASSWORD: "/auth/password/resetlink",
  RESET_PASSWORD: "/auth/password/reset",
  LOG_OUT: "/user/logout",
  REFRESH_TOKEN: "/auth/refresh",
  UPDATE_PASSWORD: "/user/password/update",
  USER_INFO: "/user/me",
  USER_REGISTRATION_CONFIRM: "/user/registrationConfirm",
  LOCK_USER: "/admin/lock",
};

export const GAME_LAUNCH = {
  GET_COIN: "/game/balance",
  TRANSFER_MONEY: "/game/fund_transfer",
  LAUNCH_GAME: "/game/launch",
  GET_LIST_GAME: "/game/list",
  GET_COIN_ALL_GAME: "/game/balance/all",
  REFUND_ONE_GAME: "/game/fund_all",
  USER_COIN: "/user/coin",
};

export const AGENCY_API = {
  AGENCY_CREATE_USER: "/agency/create_user",
  AGENCY_TRANSFER: "/agency/transfer",
  AGENCY_FUND_ALL: "/agency/fund_all",
  AGENCY_LOCK: "/agency/lock",
  AGENCY_LOCK_LO_DE: "/agency/lock_lo_de",
  AGENCY_ONLY_SPORTS: "/agency/only_sports",
  AGENCY_RESET_PASSWORD: "/agency/reset_password",
};

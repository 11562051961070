/** @format */

import {Col, Modal, Row, Table, Typography} from "antd";
import React, {useEffect} from "react";
import {useModal} from "utils/hooks/useModal";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {get} from "lodash";
import moment from "moment";
import TableHistory from "../../pages/User/TransferHistory/TableHistory";
import {Tabs} from "antd";
import type {TabsProps} from "antd";
import {coinHistory, getBalanceAll, getTransferHistory as getTransferHistoryAdmin} from "../../api/admin";

export const ModalUserInfo = (user: any) => {
    const {hideModal} = useModal();

    const roleName = {
        ROLE_USER: "User",
        ROLE_ADMIN: "Admin",
        ROLE_AGENCY: "Agency",
    };

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [total, setTotal] = React.useState(0);
    const [data, setData] = React.useState<any>([]);

    const onChange = (page: any, pageSize: any) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Log Id",
            dataIndex: "logId",
            key: "logId",
        },
        {
            title: "Thời gian",
            dataIndex: "date",
            key: "date",
            render: (value: any) => (
                <>
                    {moment(value).format("DD/MM/YY")}
                    <p data-v-30874b9e="" className="table-custom__time">
                        Lúc {moment(value).format("HH:mm")}
                    </p>
                </>
            ),
        },
        {
            title: "Trước",
            dataIndex: "before",
            key: "before",
        },
        {
            title: "Sau",
            dataIndex: "after",
            key: "after",
        },
    ];
    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "Dòng Tiền",
            children: (
                <>
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowKey="key"
                        pagination={{
                            total: total,
                            current: page,
                            pageSizeOptions: [5, 10, 15, 20, 25],
                        }}
                        onChange={(pagination) => {
                            onChange(pagination.current, pagination.pageSize);
                        }}
                        style={{background: "transparent", width: "100%", height: "200"}}
                        scroll={{x: "100%", y: 240}}
                    />
                </>
            ),
        },
        {
            key: "2",
            label: "Lịch sử chuyển Điểm",
            children: (
                <>
                    <TableHistory username={user.username}/>
                </>
            ),
        },
    ];

    useEffect(() => {
        if (user.username) {
            coinHistory({page: page - 1, pageSize, username: user.username}).then(
                (res) => {
                    setTotal(get(res, "totalItems", 0));
                    setData(get(res, "data", []));
                }
            );
            // getBalanceAll({
            //     params: {
            //         username: user.username,
            //     },
            // })
        }
    }, [page, pageSize]);

    return (
        <>
            <Modal
                title={"Thông Tin Tài Khoản"}
                visible
                onCancel={() => {
                    hideModal();
                }}
                footer={false}
                wrapClassName={classNames(styles.wrapModal)}
                centered={true}
            >
                <Row className="row">
                    <Col
                        flex="1 0 auto"
                        className="column"
                        style={{justifyContent: "center"}}
                    >
                        <p>
                            <strong>Username:</strong> {user.username}
                        </p>
                        <p>
                            <strong>Email:</strong> {user.email}
                        </p>
                        <p>
                            <strong>Agency Username:</strong> {user.agencyUsername}
                        </p>
                        <p>
                            <strong>Roles:</strong>{" "}
                            {get(user, "roles", [])
                                .map((item: string) => get(roleName, item))
                                .join(", ")}
                        </p>
                    </Col>
                    <Col flex="1 0 auto" className="column">
                        <p>
                            <strong>Số Điện thoại:</strong> {user.phoneNumber}
                        </p>
                        <p>
                            <strong>Coin:</strong> {user.coin}
                        </p>
                        <p>
                            <strong>Phần trăm Agency:</strong> {user.agencyPercent}
                        </p>
                        <p>
                            <strong>Account:</strong> {user.locked ? "Locked" : "Unlocked"}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Tabs
                        style={{color: "white", width: "100%"}}
                        defaultActiveKey="1"
                        items={items}
                    />
                </Row>
            </Modal>
        </>
    );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapModalRegister__gJAjN .ant-modal-content {
  background-color: #292929;
  border-radius: 6px;
}
.styles_wrapModalRegister__gJAjN .ant-modal-content .ant-modal-header {
  background-color: #292929;
  border-radius: 6px;
}
.styles_wrapModalRegister__gJAjN .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 32px;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .styles_wrapModalRegister__gJAjN .ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: 24px;
  }
}
.styles_wrapModalRegister__gJAjN .ant-modal-content .ant-modal-close span.ant-modal-close-x {
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/LoginForm/ModalLogin/styles.module.scss"],"names":[],"mappings":"AAEM;EACE,yBAAA;EACA,kBAAA;AADR;AAGQ;EACE,yBAAA;EACA,kBAAA;AADV;AAEU;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AAAZ;AAEU;EACE;IACE,eAAA;EAAZ;AACF;AAIU;EACE,cAAA;AAFZ","sourcesContent":[".wrapModalRegister {\n    :global {\n      .ant-modal-content {\n        background-color: #292929;\n        border-radius: 6px;\n  \n        .ant-modal-header {\n          background-color: #292929;\n          border-radius: 6px;\n          .ant-modal-title {\n            font-size: 32px;\n            color: #FFFFFF;\n            font-weight: 700;\n            text-align: center;\n          }\n          @media only screen and (max-width: 767px) {\n            .ant-modal-title {\n              font-size: 24px;\n            }\n          }\n        }\n        .ant-modal-close {\n          span.ant-modal-close-x {\n            color: #FFFFFF;\n          }\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapModalRegister": `styles_wrapModalRegister__gJAjN`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapForm__fDpUR form input {
  background: #3b3b3b;
  border-radius: 6px;
  color: #fff;
  border: none;
  padding: 6px 16px;
  width: 100%;
  font-weight: 400;
  appearance: none;
}
.styles_wrapForm__fDpUR form input .styles_ant-input-status-error__GpAKb {
  background: #3b3b3b;
}
.styles_wrapForm__fDpUR form label {
  font-size: 14px;
  color: #ffffff;
}
.styles_wrapForm__fDpUR form .styles_btnRegister__ToxlB {
  background-color: #ff4704;
  line-height: 20px;
  padding: 10px 50px;
  border: none !important;
  height: 36px;
}
.styles_wrapForm__fDpUR form .styles_textNote__p0889 {
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  line-height: 24px;
  color: #a5adb6;
  margin-bottom: 14px;
}
.styles_wrapForm__fDpUR form .styles_ant-input-status-error__GpAKb, .styles_wrapForm__fDpUR form span.styles_ant-input-affix-wrapper__zYyLe {
  background: #3b3b3b !important;
}
.styles_wrapForm__fDpUR form span.styles_ant-input-affix-wrapper__zYyLe {
  background: #3b3b3b !important;
}
.styles_wrapForm__fDpUR form .styles_ant-form-item-explain-warning__vo-\\+b {
  color: #ff4704;
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/RegisterForm/styles.module.scss"],"names":[],"mappings":"AAEI;EAIE,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EAGA,gBAAA;AAJN;AARM;EACE,mBAAA;AAUR;AAII;EACE,eAAA;EACA,cAAA;AAFN;AAII;EACE,yBAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,YAAA;AAFN;AAKI;EACE,eAAA;EACA,oBAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAHN;AAMI;EACE,8BAAA;AAJN;AAOI;EACE,8BAAA;AALN;AAQI;EACE,cAAA;AANN","sourcesContent":[".wrapForm {\n  form {\n    input {\n      .ant-input-status-error {\n        background: #3b3b3b;\n      }\n      background: #3b3b3b;\n      border-radius: 6px;\n      color: #fff;\n      border: none;\n      padding: 6px 16px;\n      width: 100%;\n      font-weight: 400;\n      -webkit-appearance: none;\n      -moz-appearance: none;\n      appearance: none;\n    }\n    \n    label {\n      font-size: 14px;\n      color: #ffffff;\n    }\n    .btnRegister {\n      background-color: #ff4704;\n      line-height: 20px;\n      padding: 10px 50px;\n      border: none !important;\n      height: 36px;\n    }\n\n    .textNote {\n      font-size: 12px;\n      font-stretch: normal;\n      font-style: normal;\n      letter-spacing: 0.75px;\n      line-height: 24px;\n      color: #a5adb6;\n      margin-bottom: 14px;\n    }\n\n    .ant-input-status-error,  span.ant-input-affix-wrapper {\n      background: #3b3b3b !important;\n    }\n\n    span.ant-input-affix-wrapper {\n      background: #3b3b3b !important;\n    }\n      \n    .ant-form-item-explain-warning {\n      color: #ff4704;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapForm": `styles_wrapForm__fDpUR`,
	"ant-input-status-error": `styles_ant-input-status-error__GpAKb`,
	"btnRegister": `styles_btnRegister__ToxlB`,
	"textNote": `styles_textNote__p0889`,
	"ant-input-affix-wrapper": `styles_ant-input-affix-wrapper__zYyLe`,
	"ant-form-item-explain-warning": `styles_ant-form-item-explain-warning__vo-+b`
};
export default ___CSS_LOADER_EXPORT___;

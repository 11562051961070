/** @format */

import { Modal } from "antd";
import classNames from "classnames";
import LoginForm from "components/PageHeader/LoginForm";
import { useModal } from "utils/hooks/useModal";
import styles from "./styles.module.scss";

type IModalSignIn = {
  title?: string;
  url?: string;
};
export const ModalSignIn = ({ title, url = "/" }: IModalSignIn) => {
  const { hideModal } = useModal();
  return (
    <>
      <Modal
        title="ĐĂNG NHẬP"
        visible
        onCancel={() => hideModal()}
        footer={false}
        wrapClassName={classNames(styles.wrapModalRegister)}
        centered={true}
      >
        {title ? <div style={{ color: "white" }}> {title}</div> : null}
        <LoginForm
          labelHas={true}
          isMobile={false}
          layout={"vertical"}
          onCloseModal={() => hideModal()}
          redirectUrl={url}
        />
      </Modal>
    </>
  );
};

/** @format */

import { compile } from "path-to-regexp";

export const compileUrl = (
  link: string,
  params?: { [x: string]: string | number } | undefined
): string => {
  const url = compile(link)(params);
  return url.toString();
};
export const formatAmount = (amount: number) => {
  if (!amount) return 0;
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
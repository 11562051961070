// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */
.styles_pageWrapper__7qPQa {
  min-height: 100vh;
  background: #1d1d1d;
}

.styles_mainWrapper__AR4Dh {
  flex: 1 1;
}

.styles_pageContent__Pdy1d {
  flex: 1 1;
  background: #1d1d1d;
}`, "",{"version":3,"sources":["webpack://./src/wrappers/AuthWrapper/styles.module.scss"],"names":[],"mappings":"AAAA,aAAA;AAEA;EACE,iBAAA;EACA,mBAAA;AAAF;;AAEA;EACE,SAAA;AACF;;AACA;EACE,SAAA;EACA,mBAAA;AAEF","sourcesContent":["/** @format */\n\n.pageWrapper {\n  min-height: 100vh;\n  background: #1d1d1d;\n}\n.mainWrapper {\n  flex: 1;\n}\n.pageContent {\n  flex: 1;\n  background: #1d1d1d;\n  // min-height: calc(100vh - 65px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": `styles_pageWrapper__7qPQa`,
	"mainWrapper": `styles_mainWrapper__AR4Dh`,
	"pageContent": `styles_pageContent__Pdy1d`
};
export default ___CSS_LOADER_EXPORT___;

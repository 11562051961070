/** @format */

import {ModalNapTienMobile} from "components/Modal/ModalNapTienMobile";
import {ModalSignIn} from "components/Modal/ModalSignIn";
import React from "react";
import {Link} from "react-router-dom";
import {useAuth} from "utils/hooks/useAuth";
import {useModal} from "utils/hooks/useModal";
import styles from "./styles.module.scss";

export const MenuMobile = () => {
    const {showModal} = useModal();
    const {token} = useAuth();

    return (
        <div className={styles.wrapMenuMobile}>
            <div id="DIV_1">
                <div id="DIV_2">
                    <Link to="/thethao">
                        <img src="/img/sport.svg" alt="icon" id="IMG_4"/>{" "}
                        <span id="SPAN_5">Thể thao</span>
                    </Link>
                    <Link to="/lode">
                        <img src="/img/casino.svg" alt="icon" id="IMG_7"/>{" "}
                        <span id="SPAN_8">Lô đề</span>
                    </Link>
                    {/*{!token ? (*/}
                    {/*    <Link to="/" id="A_9">*/}
                    {/*        <img src="/img/gift.svg" alt="icon" id="IMG_10"/>{" "}*/}
                    {/*        <span id="SPAN_11">Khuyến mãi</span>*/}
                    {/*    </Link>*/}
                    {/*) : (*/}
                    {/*    <div*/}
                    {/*        style={{*/}
                    {/*            background: "#f78f1f",*/}
                    {/*            display: "flex",*/}
                    {/*            justifyContent: "center",*/}
                    {/*            alignItems: "center",*/}
                    {/*            borderRadius: "50%",*/}
                    {/*            width: "38px",*/}
                    {/*            height: "38px",*/}
                    {/*        }}*/}
                    {/*        onClick={() => showModal(ModalNapTienMobile, {})}*/}
                    {/*    >*/}
                    {/*        <div style={{display: "flex"}}>*/}
                    {/*            <img src="/img/icon-plus.svg" alt="icon"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <div id="DIV_12">
                        <div id="DIV_13"></div>
                        <div id="DIV_14">
                            <Link to="/list-game" state={{gameType: "LIVE_CASINO"}}>
                                <img src="/img/casino.svg" alt="icon" id="IMG_7"/>{" "}
                                <span id="SPAN_8">Live Casino</span>
                            </Link>
                            <ul id="UL_19">
                                <div id="DIV_20">
                                    <div id="DIV_21">
                                        <div id="DIV_22">
                                            <div id="DIV_23">
                                                <div id="DIV_24">
                                                    <div id="DIV_25">
                                                        <img src="/img/close.svg" alt="Close" id="IMG_26"/>
                                                    </div>
                                                </div>
                                                <div id="DIV_27">
                                                    <div id="DIV_28">
                                                        <div id="DIV_29">
                                                            <div id="DIV_30">
                                                                <a href="/" id="A_31"></a>
                                                                <div id="DIV_32">
                                                                    <img
                                                                        src="/img/tele.svg"
                                                                        alt="chat"
                                                                        id="IMG_33"
                                                                    />
                                                                </div>
                                                                <div id="DIV_34">0339.778.888</div>
                                                            </div>
                                                            <div id="DIV_35">
                                                                <div id="DIV_37">
                                                                    <img
                                                                        src="/img/chat.svg"
                                                                        alt="icon"
                                                                        id="IMG_38"
                                                                    />
                                                                </div>
                                                                <div id="DIV_39">Live chat</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {token ? (
                        <Link to={"/user"}>
                            <img src="/img/account.svg" alt="icon" id="IMG_41"/>
                            <span id="SPAN_42">Tài khoản</span>
                        </Link>
                    ) : (
                        <span
                            onClick={() => {
                                showModal(ModalSignIn, {
                                    title: "Vui lòng đăng nhập để tiếp tục",
                                    url: "/user",
                                });
                            }}
                        >
              <img src="/img/account.svg" alt="icon" id="IMG_41"/>
              <span id="SPAN_42">Tài khoản</span>
            </span>
                    )}
                </div>
            </div>
        </div>
    );
};

/** @format */

import { AxiosRequestConfig } from "axios";
import Http, { sendGet, sendPost } from "./axios";
import { compileUrl } from "utils/helper";
import {AGENCY_API, AUTH_API} from "utils/definitions/api";


export const agencyCreateUser = (options?: ValueOptions | undefined) =>
    Http.post(
        compileUrl(AGENCY_API.AGENCY_CREATE_USER),
        options?.config
    );

export const agencyTransfer = (options?: ValueOptions | undefined) =>
    Http.post(
        compileUrl(AGENCY_API.AGENCY_TRANSFER),
        options?.config
    );

export const agencyFundAll = (options?: ValueOptions | undefined) =>
    Http.post(
        compileUrl(AGENCY_API.AGENCY_FUND_ALL),
        options?.config
    );

export const agencyLock = (options?: ValueOptions | undefined) =>
    Http.post(
        compileUrl(AGENCY_API.AGENCY_LOCK),
        options?.config
    );

export const agencyLockLoDe = (options?: ValueOptions | undefined) =>
    Http.post(
        compileUrl(AGENCY_API.AGENCY_LOCK_LO_DE),
        options?.config
    );

export const agencyOnlySports = (options?: ValueOptions | undefined) =>
    Http.post(
        compileUrl(AGENCY_API.AGENCY_ONLY_SPORTS),
        options?.config
    );

export const agencyResetPassword = (options?: ValueOptions | undefined) =>
    Http.post(
        compileUrl(AGENCY_API.AGENCY_RESET_PASSWORD),
        options?.config
    );
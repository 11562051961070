/** @format */

import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import classNames from "classnames";
import { handleErrorMessage } from "i18n";
import Cookies from "js-cookie";
import styles from "./styles.module.scss";
import { useModal } from "utils/hooks/useModal";
import { getUserInfo, signIn } from "api/authentication";
import { ModalResetSendMail, ModalNotifications } from "components/Modal";
import { history } from "App";
import { useAuth } from "utils/hooks/useAuth";
import { get } from "lodash";

interface IProps {
  isMobile: boolean;
  labelHas: boolean;
  layout?: any;
  widthInput?: string;
  classNameBtn?: string;
  onCloseModal?: any;
  redirectUrl?: string;
}

interface ISignIn {
  username: string;
  password: string;
}

const LoginForm = ({
  isMobile,
  labelHas,
  layout = "inline",
  widthInput = "100%",
  classNameBtn,
  onCloseModal,
  redirectUrl = "/",
}: IProps) => {
  const [form] = Form.useForm<ISignIn>();
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const { showModal, hideModal } = useModal();
  const { setAuth } = useAuth();
  const onSubmit = async (payload: ISignIn) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = true;
      return newLoadings;
    });
    if (!isMobile && (!payload.username || !payload.password)) {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[0] = false;
        return newLoadings;
      });
      return showModal(ModalNotifications, {
        message: "Vui lòng nhập tên đăng nhập và mật khẩu",
      });
    }
    return signIn({
      data: {
        username: payload.username,
        password: payload.password,
      },
    })
      .then((res: any) => {
        setTimeout(() => {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[0] = false;
            return newLoadings;
          });
          // tslint:disable-next-line:no-unused-expression
          onCloseModal && onCloseModal();
          form.resetFields();

            getUserInfo({
              config: {
                headers: {
                  Authorization: `Bearer ${res.accessToken}`,
                },
              },
            } as any)
              .then((user) => {
                setAuth({
                  token: res.accessToken,
                  roles: get(user, "roles"),
                  name: get(user, "username"),
                  showTabTransfer: get(user, "showTransferCoin"),
                  coin: get(user, "coin"),
                });
              })
              .catch(() => {
                setAuth({
                  token: res.accessToken,
                });
              });

          Cookies.set("token", res.accessToken);
          Cookies.set("refreshToken", res.refreshToken);
          Cookies.set("expiryDuration", res.expiryDuration);
          history.push(redirectUrl);
          if (redirectUrl === "/") {
            window.scrollTo(0, 0);
          }
        }, 2000);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[0] = false;
            return newLoadings;
          });

          if (err?.response?.status === 417) {
            hideModal();
            showModal(ModalNotifications, {
              message: "Tên đăng nhập hoặc mật khẩu không đúng",
              title: "LỖI ĐĂNG NHẬP",
              actionText: "Quên Mật khẩu?",
              action: () => showModal(ModalResetSendMail, { message: "test" }),
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 404) {
            showModal(ModalNotifications, {
              message:
                get(err, "response.data.message") ||
                "Tên đăng nhập hoặc mật khẩu không đúng",
              title: "LỖI ĐĂNG NHẬP",
              actionText: "Quên Mật khẩu?",
              action: () => showModal(ModalResetSendMail, { message: "test" }),
            });
          }
        }, 2000);
      });
  };

  return (
    <>
      <Form
        form={form}
        name="login"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        layout={layout}
        autoComplete="off"
        className={styles.wrapLoginForm}
      >
        <Form.Item
          label={labelHas ? "Tên đăng nhập" : ""}
          name="username"
          className={classNames(isMobile && "d-none")}
        >
          <Input placeholder="Tên đăng nhập" style={{ width: widthInput }} />
        </Form.Item>
        <Form.Item
          label={labelHas ? "Mật khẩu" : ""}
          name="password"
          className={classNames(isMobile && "d-none")}
        >
          <Input.Password
            placeholder="Mật khẩu"
            autoComplete="new-password"
            visibilityToggle={true}
            style={{
              width: widthInput,
              backgroundColor: "#343434",
              padding: "6px 10px",
              border: "none",
              color: "#a8a8a8",
              fontSize: 12,
              fontWeight: 300,
              letterSpacing: 1,
              borderRadius: 6,
            }}
            className="password"
          />
        </Form.Item>
        <Form.Item className={classNames(isMobile && "d-none")}>
          <Button
            htmlType="submit"
            className={classNames(styles.btnLogin, classNameBtn)}
            loading={loadings[0]}
          >
            Đăng nhập
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */
.styles_wrapContentCasino__jP\\+nB {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: "space-around";
}
.styles_wrapContentCasino__jP\\+nB .item {
  width: 15%;
}
@media only screen and (max-width: 998px) {
  .styles_wrapContentCasino__jP\\+nB {
    justify-content: space-around;
    gap: 15px;
  }
  .styles_wrapContentCasino__jP\\+nB .item {
    width: 45%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Casino/styles.module.scss"],"names":[],"mappings":"AAAA,aAAA;AAEA;EACE,gBAAA;EACA,aAAA;EACA,eAAA;EACA,SAAA;EACA,+BAAA;AAAF;AAEI;EACE,UAAA;AAAN;AAEI;EAJF;IAKI,6BAAA;IACA,SAAA;EACJ;EAAI;IACE,UAAA;EAEN;AACF","sourcesContent":["/** @format */\n\n.wrapContentCasino {\n  margin-top: 20px;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: \"space-around\";\n  :global {\n    .item {\n      width: 15%;\n    }\n    @media only screen and (max-width: 998px) {\n      justify-content: space-around;\n      gap: 15px;\n      .item {\n        width: 45%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapContentCasino": `styles_wrapContentCasino__jP+nB`
};
export default ___CSS_LOADER_EXPORT___;

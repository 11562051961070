/** @format */

import { Form, Input, Modal } from "antd";
import Button from "components/Button";
import React from "react";
import { useModal } from "utils/hooks/useModal";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Typography } from "antd";
import { sendMailResetPassword } from "api/authentication";
import { ModalNotifications } from "./ModalNotifications";

type ModalResetSendMailProps = {
  message: string;
  title?: string;
  action?: () => void;
  actionText?: string;
};

interface IResetPassword {
  email: string;
}

export const ModalResetSendMail = ({
  message,
  title,
}: ModalResetSendMailProps) => {
  const { hideModal, showModal } = useModal();
  const [form] = Form.useForm<IResetPassword>();
  const onFinish = (values: IResetPassword) => {
    return sendMailResetPassword({ data: { email: values.email } }).then(
      (res) => {
        hideModal();
        showModal(ModalNotifications, {
          message: "Đã gửi liên kết đặt lại mật khẩu thành công",
        });
      }
    );
  };
  return (
    <>
      <Modal
        title={title || "QUÊN MẬT KHẨU"}
        visible
        onCancel={() => {
          hideModal();
        }}
        footer={false}
        wrapClassName={classNames(styles.wrapModal)}
        centered={true}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập địa chỉ email của bạn",
              },
              { type: "email", message: "Địa chỉ email không đúng" },
            ]}
          >
            <Input placeholder="Nhập email" />
          </Form.Item>
          <Form.Item className="text-center">
            <Button type="submit">Gửi</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

/** @format */

import React, { useEffect, useReducer, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

import ModalRegister from "../RegisterForm/ModalRegister";
import LoginForm from "../LoginForm";

import useViewport from "utils/hooks/useViewport";
import ModalLogin from "../LoginForm/ModalLogin";
import Cookies from "js-cookie";
import { UserInfo } from "components/Card";
import { LogoutOutlined } from "@ant-design/icons";
import { signOut } from "api/authentication";
import { history } from "App";
import { Button } from "antd";
import { useAuth } from "utils/hooks/useAuth";

type IInitState = {
  isModalRegister?: boolean;
  isModalLogin?: boolean;
  isLogin?: boolean;
};

const RightHeader = () => {
  const isMobileMh997 = useViewport(997);
  const [isModalRegister, setIsModalRegister] = useState<boolean>(false);
  const [isModalLogin, setIsModalLogin] = useState<boolean>(false);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const { setAuth, showTabTransfer } = useAuth();
  const [state, setState] = useReducer(
    (o: IInitState, n: IInitState) => ({ ...o, ...n }),
    {
      isModalRegister: false,
      isModalLogin: false,
      isLogin: false,
    }
  );

  const handler = {
    logout: async () => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[1] = true;
        return newLoadings;
      });
      try {
        setTimeout(async () => {
          await signOut();
          Cookies.remove("token");
          Cookies.remove("refreshToken");
          history.push("/");
          setAuth({ token: "" });
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = false;
            return newLoadings;
          });
        }, 2000);
      } catch (err) {
        setTimeout(() => {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = false;
            return newLoadings;
          });
        }, 2000);
      }
    },
    onClickNapTien: () => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[2] = true;
        return newLoadings;
      });
      setTimeout(() => {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[2] = false;
          return newLoadings;
        });
        history.push("/user/deposit");
      }, 1000);
    },
  };

  const token = Cookies.get("token");

  return (
    <>
      <div className={styles.wrapForm}>
        {!token ? (
          <>
            <LoginForm
              isMobile={isMobileMh997}
              labelHas={false}
              widthInput={"174px"}
            />
            <div>
              <Button
                htmlType="button"
                className={"buttonLogin"}
                onClick={() => setIsModalLogin(true)}
              >
                Đăng nhập
              </Button>
            </div>
            <div>
              <Button
                className={"wrapRegister"}
                htmlType="button"
                onClick={() => setIsModalRegister(true)}
              >
                Đăng ký
              </Button>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UserInfo />
              {!showTabTransfer && (
                <Button
                  htmlType="button"
                  loading={loadings[2]}
                  className="wrapButtonRecharge"
                  onClick={handler.onClickNapTien}
                >
                  Nạp Tiền
                </Button>
              )}
              <Button
                onClick={handler.logout}
                loading={loadings[1]}
                className="wrapButtonLogout"
                icon={
                  <img
                    src="/img/icon-logout.svg"
                    alt="icon logout"
                    id="IMG_2"
                  />
                }
              />
            </div>
          </>
        )}
      </div>
      <ModalRegister
        visible={isModalRegister}
        onCloseModal={() => setIsModalRegister(false)}
      />
      <ModalLogin
        visible={isModalLogin}
        onCloseModal={() => setIsModalLogin(false)}
      />
    </>
  );
};

export default RightHeader;
